import { MultiSelect } from 'primereact/multiselect';
import { useState } from 'react';
import "primereact/resources/themes/tailwind-light/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css 
import data from "./../../data/jobBoardFilters.json";

export default function Location(locations, setLocations) {
    const locationOptions = data["locations"];
    let options = [];
    for (let i = 0; i < locationOptions.length; i++){
        let location = locationOptions[i];
        options.push({ value: location, label: location});
    }

    return(
        <MultiSelect 
        value={locations} 
        onChange={(e) => setLocations(e.value)} 
        options={options} 
        placeholder="Select Locations" maxSelectedLabels={1} 
        className="w-full paragraph-four text-left focus:ring-0"
        id = "dropdown"/>
    )
}

// import Select from "react-tailwindcss-select";
// import data from "./../../data/jobBoardFilters.json";

// const Locations = (locations, setLocations) => {
//     const locationOptions = data["locations"];
//     let options = [];
//     for (let i = 0; i < locationOptions.length; i++){
//         let location = locationOptions[i];
//         options.push({ value: location, label: location});
//     }

//     const handleChange = value => {
//         console.log(value)
//         setLocations(value);
//     };

//     return(
//         <Select
//         placeholder = "Available Locations"
//         value={locations}
//         onChange={handleChange}
//         options={options}
//         isMultiple={true}
//         classNames={{
//             searchBox: `border-0 bg-black`,
//             menuButton: ({ isDisabled }) => (
//                 `flex border-0 truncate bg-white h-10 overflow-x-auto paragraph-four transition-all duration-300 focus:outline-none`
//             ),}}
//         formatOptionLabel={data => (
//             <li
//                 className={`block transition duration-200 text-left px-2 py-2 cursor-pointer select-none  rounded`}
//             >
//                 {data.label}
//             </li>
//         )}/>
//     )
// }

// export default Locations;