import StatComp from "./statComponent";
import globe2 from "../../assets/Images/globe2.png"
import institution from "../../assets/Images/institution.png"
import book from "../../assets/Images/book.png"
import translate from "../../assets/Images/translate.png"
import location from "../../assets/Images/location.png"
import graduation from "../../assets/Images/graduation.png"

const Candidates = () => {
    return (
        <div>
            <p class = "paragraph-one text-paragraph-one-mobile md:text-paragraph-one text-stone-400">
                Our candidate pool
            </p>
            <p class = "sub-heading text-sub-heading-mobile md:text-sub-heading text-primary-navy md:px-64 pt-4 pb-3">
                International students and graduates from Australia's leading universities
            </p>
            <div class = "grid grid-cols-1 md:grid-cols-3 gap-x-10 gap-y-5 pt-2 md:pt-10">
                {StatComp(globe2, "52+", "countries")}
                {StatComp(institution, "30+", "institutions")}
                {StatComp(book, "50+", "areas of study")}
                {StatComp(translate, "98%", "multilingual")}
                {StatComp(location, "79%", "open to relocate")}
                {StatComp(graduation, "40%", "masters degree")}
            </div>
        </div>    
    );
  };

export default Candidates;