import PartnerImages from "../Partners/partners";

const Landing = () => {
    return (
        <div>
            <p class = "px-5 text-paragraph-one-mobile md:text-paragraph-one paragraph-one text-stone-400 pb-5">
                Our Partners
            </p>
            <p class = " pb-5 text-sub-heading-mobile md:text-sub-heading sub-heading text-primary-navy">
                Championing diversity together
            </p>
            <PartnerImages/>
        </div>    
    );
  };

export default Landing;