import './jobBoardCard.css';

const JobBoardCard = (job) => {
  const jobLink = `/job-details?id=${job.id}`
    return (
    <a href={jobLink} class = "">
      <div className={` h-full drop-shadow-md hover:drop-shadow-xl rounded-lg`} id = {`${job.fields["Premium"] === 'Yes' ? 'featured-jobCard' : ''}`}>
        <div class = "card bg-white rounded-lg grid grid-cols-12 h-full py-5 px-5 gap-x-2">
          <div class = "col-span-4 md:col-span-3 h-full">
            <div class = "grid place-items-center h-full">
              <img class = "w-full px-2" alt = "Logo Error" src = {job.fields["Company Logo"][0].url}/>
            </div>
          </div>
          <div class = "col-span-8 md:col-span-9 h-full">
            <div class = "grid items-end h-full leading-tight">
              <p class = "text-left paragraph-one-bold text-paragraph-one-mobile md:text-paragraph-one">
                {job.fields["Job Role"]}
              </p>
              <p class = "pt-2 text-left md:paragraph-three paragraph-three text-paragraph-three-mobile md:text-paragraph-three text-stone-500">
                {job.fields["Company Name"]}
              </p>
            </div>
          </div>
          {job.fields["Premium"] === 'Yes' ? 
            <div class = "col-span-12 h-10 md:h-16 text-left flex items-center">
                <span class = "text-white ml-2 w-fit h-fit rounded-2xl bg-primary-lilac text-paragraph-five-mobile md:text-paragraph-five px-2 py-1 paragraph-three-bold">
                  Featured
                </span>
            </div>: 
            <div class = "col-span-12 h-10 md:h-16"></div>
          }
          <div class = "col-span-10">
            <div class = "grid grid-cols-12 gap-x-2 gap-y-1 md:gap-y-3">
              <div class = "col-span-2 px-2 flex items-center">
                <img src={require('./../../assets/Images/Icons/industry.png')} class = "md:px-0.5 mb-0.5 w-full"/>
              </div>
              <div class = "col-span-10 h-full flex items-center mt-1">
              <p class = "text-left paragraph-five text-paragraph-four-mobile md:text-paragraph-four truncate">
                {job.fields["Industry"].map((industry, i, industries) => (
                  <>
                  {(i == industries.length - 1)?
                    <span>{industry} </span>:
                    <span>{industry}, </span>
                  }
                  </>
                ))}
              </p>
              </div>
              <div class = "col-span-2 px-2 flex items-center">
                <img src={require('./../../assets/Images/Icons/jobType.png')} class = "md:px-0.5 w-full"/>
              </div>
              <div class = "col-span-10 flex items-center">
              <p class = "text-left paragraph-five text-paragraph-four-mobile md:text-paragraph-four">
                {job.fields["Job Type"].map((jobType, i, jobTypes) => (
                  <>
                  {(i == jobTypes.length - 1)?
                    <span>{jobType} </span>:
                    <span>{jobType}, </span>
                  }
                  </>
                ))}
              </p>
              </div>
              <div class = "col-span-2 px-2 flex items-center">
                <img src={require('./../../assets/Images/Icons/location.png')} class = "md:px-0.5 w-full"/>
              </div>
              <div class = "col-span-10 flex items-center">
              <p class = "text-left paragraph-five text-paragraph-four-mobile md:text-paragraph-four truncate">
                {job.fields["Location"].map((location, i, locations) => (
                  <>
                  {(i == locations.length - 1)?
                    <span>{location} </span>:
                    <span>{location}, </span>
                  }
                  </>
                ))}
              </p>
              </div>
              <div class = "col-span-2 px-2 flex items-center">
                <img src={require('./../../assets/Images/Icons/prReq.png')} class = "md:px-0.5 w-full"/>
              </div>
              <div class = "col-span-10 flex items-center">
              <p class = "text-left paragraph-five text-paragraph-four-mobile md:text-paragraph-four">
                {job.fields["PR Requirement"]}
              </p>
              </div>
            </div>
          </div>
          <div class = "col-span-2 flex items-end relative">
            <img src={require('./../../assets/Images/Icons/forward.png')} class = "absolute right-0 w-1/2"/>
          </div>
        </div>
      </div>
    </a>

    );
  };

export default JobBoardCard;