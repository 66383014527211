import Landing from "../components/AboutUs/landing";
import OurStory from "../components/AboutUs/ourStory";
import OurValues from "../components/AboutUs/ourValues";
import FounderCard from "../components/AboutUs/founderCard";
import PartnerImages from "../components/Partners/partners";
import ImageCarousel from "../components/AboutUs/imageCarousel";

// Image Imports
import Aus from './../assets/Images/Aus.png'
import Nathan from './../assets/Images/Nathan.jpg'
import Sg from './../assets/Images/Sg.png'
import Enya2 from './../assets/Images/Enya2.jpg'
import Indo from './../assets/Images/Indo.png'
import Nicole from './../assets/Images/Nicole.jpg'
import Msia from './../assets/Images/Msia.png'
import HH from './../assets/Images/HH.jpg'

const AboutUs = () => {
    function learnMoreScroll(){
        const element = document.getElementById("ourStory");
        if (element) {
          setTimeout(function () {
            element.scrollIntoView({
                block: "start",
            });
        }, 100);
        }
      }
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>
        <div>
            <div>
                {Landing(learnMoreScroll)}
            </div>
            <div class = "backgroundS">
                <div class = "px-8 md:px-64" id = "ourStory">
                    <OurStory/>
                </div>
                <div class = "pt-10 md:pt-20 pb-5">
                    <ImageCarousel/>
                </div>
                <div class = "mx-10 md:mx-32 lg:mx-64 xl:mx-96 backgroundComma py-0 md:py-16 my-5 md:mb-16 md:mt-16">
                    <p class = " paragraph-one-bold text-paragraph-one-mobile md:text-paragraph-one text-primary-purple">
                        Let's embrace diversity and unlock a new era of success for the Australian workforce.
                    </p>
                </div>
                <div class = "pt-12 md:pt-0 pb-10 md:pb-24">
                    <OurValues/>
                </div>
                <div class = "bg-secondary-steel text-white text-left py-10 px-8 md:py-20 md:px-16">
                    <p class = "section-heading text-section-heading-mobile md:text-section-heading">
                        Meet our co-founders 
                    </p>
                    <p class = "paragraph-three text-paragraph-three-mobile md:text-paragraph-three text-white pt-8 pb-10">
                        Meet the people behind the creation of Stint who have lived experiences as international students and graduates in Australia.
                    </p>
                    <div class = "grid grid-cols-1 md:grid-cols-4 md:gap-x-5 mx-8 md:mx-0 gap-y-5">
                        <div>
                            {FounderCard(Nathan, Aus, "Nathan Lee", "https://www.linkedin.com/in/nathan-lee838/", "University of Melbourne, B.Comm.,(Accounting and Finance)")}
                        </div>
                        <div>
                            {FounderCard(Enya2, Sg, "Enya Loo", "https://www.linkedin.com/in/enyaloo/", "University of Melbourne, B., Arts (Politics and International Relations)")}
                        </div>
                        <div>
                            {FounderCard(Nicole, Indo, "Nicole Tjahyadi", "https://www.linkedin.com/in/nicole-tjahyadi-58a672190/", "Melbourne Law School, Juris Doctor")}
                        </div>
                        <div>
                            {FounderCard(HH, Msia, "Koh Hui Hong", "https://www.linkedin.com/in/huihongkoh/", "University of Melbourne, B.Comm.,(Accounting and Finance)")}
                        </div>
                    </div>
                </div>
                <div>
                    <p class = "pt-12 paragraph-one text-paragraph-one-mobile md:text-paragraph-one text-stone-400">
                        Our partners
                    </p>
                    <p class = "pt-2 pb-6 sub-heading text-sub-heading-mobile md:text-sub-heading text-primary-navy">
                        Championing diversity together 
                    </p>
                    <div class = "pb-12">
                        <PartnerImages/>
                    </div>
                </div>
                <div class = "bg-secondary-stone py-5">
                    <p class = "pt-20 section-heading text-section-heading-mobile md:text-section-heading text-primary-purple">
                        Want to chat?
                    </p>
                    <p class = "py-4 px-8 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">
                        Are you an international student or employer with questions, ideas or feedback, let us know!
                    </p>
                    <button class = "mb-12 mt-10 bg-white px-3 py-2 rounded-full">
                    <a href = "https://airtable.com/appyT1YN8YDfocPTw/shrLi8VMRhMEWRpcd">
                        <p class = "button text-button-mobile md:text-button text-black text-center">
                            Get in touch
                        </p>
                    </a>
                    </button>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default AboutUs;