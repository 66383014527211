import Title from "../../components/Blogs/title";
import PrachiAuthor from '../../assets/Images/PrachiAuthor.jpg'

import { useEffect } from "react";

const GlobalTalentLocalGain = () => {
    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/insights" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/GlobalTalentLocalGain.jpg')} class = "object-contain w-full"/>
                    <p class = "paragraph-four text-left text-stone-400 mt-3 text-paragraph-four-mobile md:text-paragraph-four w-5/6">
                        {/* Credits: Ailene Susanto */}
                    </p>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Hiring", 
                        "Global Talent, Local Gain: Why Hiring International Students Benefits Australian Employers", 
                        "International students are drawn to Australia for its safety, high-quality education, opportunity to learn English, and vibrant lifestyle. In fact, Australia ranks as one of the top attractive destinations for international students globally. I can personally relate, having arrived in 2016 to pursue higher studies at the University of Melbourne—a decision influenced by the university’s stellar reputation and the opportunities it offered.",
                        "Prachi Tyagi", 
                        PrachiAuthor, 
                        "24th September 2024")}
                </div>
            </div>

            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        
                        International students are drawn to Australia for its safety, high-quality education, opportunity to learn English, and vibrant lifestyle. In fact, Australia ranks as one of the top attractive destinations for international students globally. I can personally relate, having arrived in 2016 to pursue higher studies at the University of Melbourne—a decision influenced by the university’s stellar reputation and the opportunities it offered. <br></br><br></br><br></br>
                        Fast forward two years, I, like many graduates, was on the hunt for full-time work in my field. While securing interviews was promising, I quickly noticed a recurring challenge: once visa status came up, many employers seemed unsure about what the Temporary Graduate Visa (subclass 485) entailed, especially in terms of work rights and how "temporary" the visa truly was. <br></br><br></br><br></br>
                        A recent Grattan Institute report, <a class = "text-blue-500 hover:text-blue-800 underline" href = "https://grattan.edu.au/report/graduates-in-limbo/">Graduates in Limbo: International Student Visa Pathways after Graduation</a> highlights that one of the main reasons international graduates struggle to secure employment is due to employer hesitation around hiring temporary visa holders. Many employers are unfamiliar with the full work rights the Temporary Graduate visa provides. <br></br><br></br><br></br>
                        Additionally, there’s a perception that hiring international graduates is more expensive and that they may require extra training to adapt to Australian workplace culture. However, with the right understanding of visa conditions and a focus on the global experience international graduates bring, these concerns can easily be addressed. <br></br><br></br><br></br>
                        Hiring international students isn’t just about filling roles—it’s about accessing a diverse talent pool with unique perspectives that can drive innovation and contribute to an increasingly globalised economy.<br></br><br></br><br></br>
                        In fact, I explored this topic in depth on the <a class = "text-blue-500 hover:text-blue-800 underline" href = "https://www.idp.com/australia/community/idp-real-talk-job-hunting-tips-for-international-students/">IDP Real Talk for International Students podcast</a>  with Stint Community co-founders Nathan and Enya, sharing insights to help transform international students’ approach to job hunting and career development in Australia.


                        {/* Embed the Spotify episode */}
                        <div className="pt-8 md:pt-16">
                            <iframe 
                                style={{ borderRadius: "12px" }} 
                                src="https://open.spotify.com/embed/episode/2NZbDIMYLAFdxWDpm5PdmX?utm_source=generator" 
                                width="100%" 
                                height="152" 
                                frameBorder="0" 
                                allowFullScreen="" 
                                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                                loading="lazy">
                            </iframe>
                        </div><br></br><br></br><br></br>


                        <span class = "font-extrabold">What international students bring to the Australian workforce: </span><br></br><br></br>

                        <span class = "font-extrabold">1. Cultural Diversity and Global Perspective: </span>
                        Many international students come with prior work experience from their home countries, bringing valuable insights that can enhance a company's ability to engage with and understand global markets.<br></br><br></br>

                        <span class = "font-extrabold">2. Talent Pool and Skillset: </span>
                        Many international students are likely bilingual (or even multilingual) and possess specialised knowledge of overseas markets and trends, making them highly valuable assets to employers.<br></br><br></br>
                        
                        <span class = "font-extrabold">3. Employer Branding and Reputation: </span>
                        Bringing international students on board showcases a company’s commitment to diversity, inclusion, and building a multicultural workplace—an advantage in Australia's increasingly diverse society.<br></br><br></br>
                        
                        <span class = "font-extrabold">4. Potential for Long-Term Retention: </span>
                        Many international students aspire to remain in Australia after finishing their studies. It's important for employers to understand the Temporary Graduate visa and the pathways that follow. Even when the Temporary Graduate visa expires, students on a bridging visa still maintain full work rights, making them valuable candidates for ongoing employment.<br></br><br></br><br></br>
                        <br></br>




                        {/* EMPLOYER QUESTIONS */}
                        <span class = "font-extrabold"> Here are some common questions employers ask:  </span><br></br><br></br>

                        <span class = "font-extrabold">1. What work rights do international students have upon graduation?</span><br></br>
                        Upon graduation, international students in Australia can access work opportunities through the Temporary Graduate Visa (Subclass 485). As of 1 July 2024, <a class = "text-blue-500 hover:text-blue-800 underline" href = "https://immi.homeaffairs.gov.au/visas/getting-a-visa/visa-listing/temporary-graduate-485">this visa offers three distinct streams</a>:<br></br><br></br>
                        
                        <div>
                        <ul style={{ listStyleType: '"\\2022"', paddingLeft: "50px" }}>
                            <li style={{ textIndent: "10px" }}><span class = "font-bold">Post-Vocational Education Work stream (former Graduate Work stream)</span></li><br></br>
                            <li style={{ textIndent: "10px" }}><span class = "font-bold">Post-Higher Education Work stream (former Post-Study Work stream):</span> This is a flexible option that allows graduates to work in any field for up to four years, regardless of their area of study or Australia's current skills shortages.</li><br></br>
                            <li style={{ textIndent: "10px" }}><span class = "font-bold">Second Post-Higher Education Work stream (former Second Post-Study Work stream):</span> Applicants under the age of 50 are eligible for this stream if they have used a Master's (Research) or Doctoral Degree (PhD) to meet the requirements for obtaining their Post-Higher Education Work visa.</li><br></br>
                        </ul>
                        </div><br></br>

                        
                        <span class = "font-extrabold">2. Do international students on the 485 visa require employer sponsorship?</span><br></br>
                        A 485 visa holder does not require employer sponsorship. Some employers may opt to sponsor international graduates for a permanent visa after they have gained work experience on the 485 visa.<br></br><br></br><br></br>             


                        <span class = "font-extrabold">3. What happens when the 485 temporary visa expires?</span><br></br>
                        Once the 485 Temporary Graduate Visa expires, international students can either transition to another visa subclass, such as the 191 Permanent Skilled Regional (Employer Sponsored) or 189 Permanent Skilled Independent Visa, to extend their stay in Australia. Alternatively, they may choose to return to their home country to pursue career opportunities there.<br></br><br></br><br></br>


                        <span class = "font-extrabold">4. What does it mean if a graduate student is on a bridging visa?</span><br></br>
                        It's important to note that students on a bridging visa may have full work rights. Recruiters can verify a student's work rights by checking their VEVO visa status. This ensures compliance with Australian immigration regulations.<br></br><br></br><br></br>
                        


                        {/* Divider line */}
                        <hr style={{ borderTop: "1px solid #ccc", marginTop: "20px", marginBottom: "20px" }} /><br></br>

                        In conclusion, take the opportunity to interact with international students - Connect with them on LinkedIn, engage with their discussions, or meet for a coffee. You might discover just how valuable their diverse perspectives can be for your organisation.<br></br><br></br>

                        If you're an Australian employer, reach 15k+ multilingual, diverse, international candidates by listing on Stint’s job board <a class = "text-blue-500 hover:text-blue-800" href = "https://www.stintcommunity.com/?=offerings">here</a>. 

                    </p>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default GlobalTalentLocalGain;