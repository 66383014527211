import Title from "../../components/Blogs/title";
import Ashley from '../../assets/Images/Ashley.jpg'
import hrefLink from "../../components/General/hrefLink";
import { useEffect } from "react";

const UpDown = () => {
    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/blogs" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/laughing.jpg')} class = "object-contain w-full"/>
                    <p class = "paragraph-four text-left text-stone-400 mt-3 text-paragraph-four-mobile md:text-paragraph-four w-5/6">
                        Credits: Priscilla Du Preez on {hrefLink("Unsplash", "https://unsplash.com/photos/three-men-laughing-while-looking-in-the-laptop-inside-room-XkKCui44iM0?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash")}
                    </p>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Hiring", 
                        "The Ups and Downs of Job Hunting: Abhi Kansakar", 
                        "Job hunting is one of the cornerstones of adulthood. Ask anyone, and I guarantee that you’ll find an abundance of students, international and domestic alike, that say they are on the lookout for a way to progress into the professional scene. Abhi Kansakar is one of them.",
                        "Ashley Yeow", 
                        Ashley, 
                        "18th October 2024")}
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    The international student had chosen to study in Australia not only because of the wealth of thriving startups and burgeoning businesses, but also because the Australian culture champions creativity, collaboration and open-mindedness, traits that have allowed Kansakar to thrive in both his personal and professional life. <br/><br/>

                        “I had the privilege of joining the Master of Finance program at the University of Melbourne,” Abhi explains. He chose to stay on to pursue his post-graduate after obtaining an undergraduate degree in both Finance and Marketing. “It was a great opportunity to further my understanding of finance and prepare for my career ahead.” <br/><br/>

                        He further explains how he has also learned so many invaluable lessons and tidbits while he was studying and working in Melbourne. From things such as crucial internships to meaningful volunteer work, these experiences have been enjoyable and extremely enlightening. The culture in Melbourne has been one that he touts as ‘warm and inclusive’ which has only furthered his drive to find a job in Australia. <br/><br/>

                        “The warmth, support, and sense of community that I’ve experienced here has been truly remarkable,” Abhi comments. “The inclusive and diverse society here fosters an environment that resonates deeply with my values and personality. My mentors, colleagues, and industry connections have played a pivotal role in my growth and development… and have been instrumental in my ongoing educational and professional success.” <br/><br/>

                        Many international students consider Australia their second home, the community here one that has accepted them with open arms, arms that can make equally good coffee and food. The international student recalls the start of his working career in the world’s most livable city, in an equally recognisable brand - the Golden Arches. <br/><br/>

                        “I started my journey in the hospitality sector at McDonalds,” Kansakar recounts. “I often worked late and overnight shifts, particularly on weekends which taught me resilience and adaptability right from the beginning.” <br/><br/>

                        The traits that he has picked up and honed during his time in the hospitality sector are ones that he still finds himself using today, especially when he ventures into the competitiveness that is the Australian job hunting scene.<br/><br/>

                        The pursuit for a place to work, while a challenge for Abhi, was also something that he considered rewarding and enjoyable. Thriving off of the lessons he picked up from various interview experiences to assessment centers, Kansakar considers this phase of his life one of his most exciting ones to date.<br/><br/>

                        And it paid off when he found himself with multiple job offers on the table.<br/><br/>

                        The process of searching for a pathway to employment in Australia was one where he felt he grew the most. It allowed him to refine the skills he learnt during his time in the hospitality sector to gain valuable insights on the working culture in Melbourne but he also cautions us with his share of hardships too.<br/><br/>

                        Then again, it’s *always* easier said than done.<br/><br/>

                        “Opportunities should unquestionably be awarded based on merit, irrespective of an individual’s background or origin,” he says, “A diverse workforce is widely recognised as a cornerstone of a company’s overall success as it fosters a wealth of innovative and creative solutions when teams encounter challenges.”<br/><br/>

                        Kansakar speaks to the all too common challenge that international students face when choosing to enter the Australian workforce.<br/><br/>

                        Many international students find themselves at the brunt of an unfair recruiting process; their resumes could be screened before an interview and sometimes they aren’t even considered for *unpaid* internships. Only the cream of the crop of international students are considered, especially since the recruitment process for domestic students can be much more lenient.<br/><br/>

                        Upholding the tenets of equality and diversity in recruiting is something that companies within Australia should always have at the forefront of their minds. An international workforce is to companies what oxygen is to breathing: essential.  Ranging from a diversity in solving challenges to bringing in overseas opportunities to boost local economies which in the long term, benefits a company’s bottom line.<br/><br/>

                        But this isn’t a message of despair. This is anything but that. While there have been many instances of international students being overlooked during the hiring process, it is important to also note that there is a light at the end of the tunnel.<br/><br/>

                        Just look to Abhi as one of them.<br/><br/>

                        “It is essential that companies recognise the value of a diverse workforce,” he concludes the interview on a positive note. “And in my choice to make Australia, specifically Melbourne, my home is a reflection of my commitment to continuous growth, and I’m dedicated to contributing meaningfully to the vibrant community here in Victoria.”
                    </p>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default UpDown;