const OurStory = () => {
    return (
        <div>
            <p class = "section-heading text-section-heading-mobile md:text-section-heading text-primary-purple">
                Our story
            </p>
            <div class = 'grid gap-y-5 pt-10'>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two text-primary-purple">
                        As current and former international students, we know how challenging it can be trying to build a successful career in Australia. We feel invisible or overlooked on normal job platforms, and finding employers willing to hire international students seemed almost impossible.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two text-secondary-coral">
                        That's why we created Stint.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two text-primary-purple">
                        Since launching in 2022, Stint has helped thousands of international students to find their place in the Australian job market. We bridge the gap between students and employers, helping connect talented, multilingual young professionals with Australian companies who celebrate diversity.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two text-primary-purple">
                        There are too many talented international students in Australia, who ultimately leave because they can’t find the right jobs, at the right companies. This impacts the Australian workforce, economy and cultural diversity. Stint is here to change that. 
                    </p>
                </div>
            </div>
        </div>
    );
  };

export default OurStory;