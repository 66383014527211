const Stats = () => {
    return (
        <div class = "relative w-full z-10">
            <img loading="lazy" class = "h-5/6 w-full object-cover" alt = "Image Error" src={require('./../../assets/Images/Stat2.png')}/>
            <div class = "leading-semi-tight grid grid-cols-12 text-left px-4 md:px-0 md:text-center w-full absolute pb-3 md:pb-10 bottom-0">
                <div class = "col-span-12 md:col-span-4">
                    <p class = "inline md:block text-primary-purple text-sub-heading-mobile md:text-main-heading main-heading">
                        5,000+ ‎
                    </p>
                    <p class = "inline md:block w-full paragraph-one-bold text-paragraph-four-mobile md:text-paragraph-one">
                        students in ‎ 
                    </p>
                    <p class = "inline md:block leading-tight w-full paragraph-one-bold text-paragraph-three-mobile md:text-paragraph-one">
                        our community
                    </p>
                </div>
                <div class = "col-span-12 md:col-span-4">
                    <p class = "inline md:block text-primary-purple text-sub-heading-mobile md:text-main-heading main-heading">
                        2,000+ ‎
                    </p>
                    <p class = "inline md:block w-full paragraph-one-bold text-paragraph-four-mobile md:text-paragraph-one">
                        jobs have ‎ 
                    </p>
                    <p class = "inline md:block leading-tight w-full paragraph-one-bold text-paragraph-three-mobile md:text-paragraph-one">
                        been listed
                    </p>
                </div>
                <div class = "col-span-12 md:col-span-4">
                    <p class = "inline md:block text-primary-purple text-sub-heading-mobile md:text-main-heading main-heading">
                        1,500+ ‎
                    </p>
                    <p class = "inline md:block w-full paragraph-one-bold text-paragraph-four-mobile md:text-paragraph-one">
                        students attended ‎ 
                    </p>
                    <p class = "inline md:block leading-tight w-full paragraph-one-bold text-paragraph-three-mobile md:text-paragraph-one">
                        our career events
                    </p>
                </div>
            </div>
        </div>  

    );
  };

export default Stats;