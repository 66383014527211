const SellingPoint = (image, title, text) => {
    return (
        <div class = "bg-white rounded-2xl drop-shadow-xl h-full relative">
            <p class = "px-5 md:px-10 paragraph-one-bold text-paragraph-one-mobile md:text-paragraph-one text-paragraph-one-mobile md:text-paragraph-one pt-6 text-primary-purple">
                {title}
            </p>
            <p class = "px-5 md:px-2 paragraph-two pt-6 text-paragraph-two-mobile md:text-paragraph-two md:text-caption text-primary-purple">
                {text}
            </p>
            <div class = "bg-white md:h-32 lg:h-64 h-32 mt-6 rounded-2xl">
            </div>
            <div class = "align-baseline bottom-0 absolute justify-start items-start mt-6">
                <img loading="lazy" src={image} class = "h-32 md:h-32 lg:h-64 rounded-2xl "/>
            </div>
        </div>
    );
  };

export default SellingPoint;