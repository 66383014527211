import Title from "../../components/Blogs/title";
import Ashley from "../../assets/Images/Ashley.jpg"
import { useEffect } from "react";

// Remove this 
import BlogImageCredit from "../../components/Blogs/blogImageCredit";
import PalleteImage from "./../../assets/Images/Pallete.png";

const StudyPlaces = () => {
  
    const credit = "@palette_melbourne"

    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/blogs" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full h-full">
                    <img src={require('./../../assets/Images/LibraryAtDock1.png')} class = "object-contain h-full"/>
                </div>
                <div class = "pt-5 md:pt-0 grid place-items-center col-span-12 md:col-span-6">
                    {Title("Lifestyle", 
                        "Stint’s 5 Best Study Places to Help You Get Out of Your Study Funk", 
                        "Studying at home getting you down in the dumps? Want to find a new place to refresh your mind and to get you in the zone? Well Stint has got your back! We have come up with five must-gos for students who are looking for a new space to sit and work in. Ranging from quiet, underrated locations to exciting, spirited cafes, Stint’s got everything covered for you.",
                        "Ashley Yeow", 
                        Ashley, 
                        "27th February 2023")}
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "sub-heading text-sub-heading-mobile md:text-sub-heading">
                        Palette
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Everyone knows about Palette at night., with their amazing desserts and vibey atmosphere, but what about Palette not at night? The cafe in the mornings is a lot quieter than its nightly counterpart, and so the bustling dessert place becomes the new perfect place to study during the daytime. Not only does the shop boast amazing Korean sandwich options, the coffee here can definitely hold its own weight against other cafes in the CBD, which is truly all that matters here in Melbourne no?
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        The menu here does provide vegetarian options, but unfortunately for our vegan friends, you’re out of luck :( For everyone else, our Stint recommendations hands down has to be the garlic, bacon and cheese brioche (priced at $16.50). Yum.
                    </p>
                </div>
                <BlogImageCredit
                imagePath = {PalleteImage}
                credit = {credit}
                />
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        The atmosphere in the morning is cozy and peaceful, with sunlight always pouring into the cafe, making it the go-to place for students and professionals alike to catch up on work. While we here at Stint love Palette at night., Palette during the day truly holds a special place in our hearts.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Address: 610 Queensberry St, North Melbourne VIC 3051
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Opening Hours
                    </p>
                    <div class = "grid grid-cols-3 w-full md:w-3/6">
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Monday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">8:00am – 3:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Tuesday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">8:00am – 3:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Wednesday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">8:00am – 3:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Thursday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">8:00am – 3:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Friday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">8:00am – 3:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Saturday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">8:00am – 3:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Sunday</div><div class = "col-span-2  paragraph-two text-paragraph-two-mobile md:text-paragraph-two">8:00am – 3:00pm</div>
                    </div>
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "sub-heading text-sub-heading-mobile md:text-sub-heading">
                        Udom House
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Bringing your attention back to cafes, Udom House is another one of our recommendations for one of the best places to study. Serving Thai snacks and food, Udom House is a great place to get away from the hustle and bustle of the Melbourne CBD without being too far out of the way. With a cozy and homey vibe, Udom House serves some of the best Thai-inspired cafe snacks you can find. Definitely a perfect pair with that essay you’ve been putting off huh?
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Udom House does provide our vegan friends some options, and honestly they are really, really good. The Yum Eggplant (which is grilled eggplant with a bunch of herbs and spices for $16), truly lives up to its name of being yum.
                    </p>
                </div>
                <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                    <div class = "col-span-12 md:col-span-6">
                        <img src={require('./../../assets/Images/Udom House.png')} class = "w-full"/>
                    </div>
                    <div class = "col-span-12 md:col-span-6 h-full flex items-end">
                        <p class = "caption text-caption-mobile md:text-caption text-gray-500">
                            Credit: Google Images
                        </p>
                    </div>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        With relaxing music and good food, there’s really nothing else you need to ask for. However, Udom House is currently on a break, and will only be back after the 8th of March. But once they are up and running once again, we highly recommend you give them a check out!
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Address: Victoria Buildings, 343 Victoria St, West Melbourne VIC 3003
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Opening Hours
                    </p>
                    <div class = "grid grid-cols-3 w-full md:w-3/6">
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Monday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">7:30am-3:30pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Tuesday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Closed</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Wednesday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">7:30am-3:30pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Thursday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">7:30am-3:30pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Friday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">7:30am-3:30pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Saturday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">9:00am-3:30pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Sunday</div><div class = "col-span-2  paragraph-two text-paragraph-two-mobile md:text-paragraph-two">9:00am-3:30pm</div>
                    </div>
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "sub-heading text-sub-heading-mobile md:text-sub-heading">
                        Le Bajo Milkbar
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Got milk? Well we’ve got Le Bajo Milkbar. Known for its mix of industrial and organic design, Le Bajo Milkbar is one of our recommendations for best study places in Melbourne. To fuel your study brain, the cafe offers a mix of savoury and sweet Japanese sandwiches that will leave you satisfied and ready to tackle whatever is left on your study plate. Owned by the same person that runs 279, Le Bajo Milkbar has the same peaceful, relaxed energy that will keep you in the zone for hours on end.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        This store is another bust for the vegans sadly, offering only vegetarian options on their menu. But for people who don’t mind 🤭, their Chicken Katsu Sando ($18.00) is pretty much perfect.
                    </p>
                </div>
                <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                    <div class = "col-span-12 md:col-span-6">
                        <img src={require('./../../assets/Images/LeBajo1.png')} class = "w-full"/>
                    </div>
                    <div class = "md:hidden col-span-12 h-full flex items-end">
                        <p class = "caption text-caption-mobile md:text-caption text-gray-500">
                            Credit: broadsheet.com.au
                        </p>
                    </div>
                    <div class = "col-span-12 pt-5 md:pt-0 md:col-span-6 w-full h-fit">
                        <img src={require('./../../assets/Images/LeBajo2.png')} class = "w-full"/>
                    </div>
                    <div class = "hidden md:block col-span-6 h-full flex items-end">
                        <p class = "caption text-caption-mobile md:text-caption text-gray-500">
                            Credit: broadsheet.com.au
                        </p>
                    </div>
                    <div class = "col-span-12 md:col-span-6 h-full flex items-end">
                        <p class = "caption text-caption-mobile md:text-caption text-gray-500">
                            Credit: @lebajo_milkbar
                        </p>
                    </div>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        The cafe also has a small retail section to satisfy that retail therapy session you need after a hard day’s work of studying!
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Address: 8-14 Howard Street North Melbourne 3051
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Opening Hours
                    </p>
                    <div class = "grid grid-cols-3 w-full md:w-3/6">
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Monday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">9:30am-3:30pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Tuesday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">9:30am-3:30pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Wednesday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">9:30am-3:30pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Thursday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">9:30am-3:30pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Friday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">9:30am-3:30pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Saturday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">9:30am-3:30pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Sunday</div><div class = "col-span-2  paragraph-two text-paragraph-two-mobile md:text-paragraph-two">9:30am-3:30pm</div>
                    </div>
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "sub-heading text-sub-heading-mobile md:text-sub-heading">
                        Library At the Dock
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    While we know that State Library is a crowd favourite, Stint sees this and raises you, Library at the Dock. A secluded library situated in the heart of Docklands, it is one of the most gorgeous public libraries in Melbourne. The building showcases modern architecture at its finest, with an abundance of study areas and open floor plan workspaces for all to come and enjoy.
                    </p>
                </div>
                <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                    <div class = "col-span-12 md:col-span-6">
                        <img src={require('./../../assets/Images/LibraryAtDock1.png')} class = "w-full"/>
                    </div>
                    <div class = "col-span-12 md:col-span-6 h-full flex items-end">
                        <p class = "caption text-caption-mobile md:text-caption text-gray-500">
                            Credit: https://edgearchitectural.com.au
                        </p>
                    </div>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6 pt-4">
                    Office spaces and workrooms not your thing? That’s all good too! The library also has tables which overlook the river, giving you time and space to relax. If you’re looking for a place to take a break after studying, Library at the Dock also has indoor table tennis rooms where you can work up a sweat to wind down after studying with friends!
                    </p>
                </div>
                <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                    <div class = "col-span-12 md:col-span-6">
                        <img src={require('./../../assets/Images/LibraryAtDock2.png')} class = "w-full"/>
                    </div>
                    <div class = "md:hidden col-span-12 h-full flex items-end">
                        <p class = "caption text-caption-mobile md:text-caption text-gray-500">
                            Credit: City of Melbourne
                        </p>
                    </div>
                    <div class = "col-span-12 pt-5 md:pt-0  md:col-span-6 w-full h-fit">
                        <img src={require('./../../assets/Images/LibraryAtDock3.png')} class = "w-full"/>
                    </div>
                    <div class = "hidden md:block col-span-6 h-full flex items-end">
                        <p class = "caption text-caption-mobile md:text-caption text-gray-500">
                            Credit: City of Melbourne
                        </p>
                    </div>
                    <div class = "col-span-12 md:col-span-6 h-full flex items-end">
                        <p class = "caption text-caption-mobile md:text-caption text-gray-500">
                            Credit: City of Melbourne
                        </p>
                    </div>
                </div>
                <div>
                    <p class = "paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Address: 107 Victoria Harbour Promenade, Docklands VIC 3008
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Opening Hours
                    </p>
                    <div class = "grid grid-cols-3 w-full md:w-3/6">
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Monday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">10:00am-6:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Tuesday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">10:00am-6:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Wednesday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">10:00am-6:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Thursday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">10:00am-6:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Friday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">1:00pm-6:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Saturday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">10:00am-1:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Sunday</div><div class = "col-span-2  paragraph-two text-paragraph-two-mobile md:text-paragraph-two">12:00pm-4:00pm</div>
                    </div>
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "sub-heading text-sub-heading-mobile md:text-sub-heading">
                        Auction Rooms
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        To round off our list, we have Auction Rooms! This place is definitely one of our fancier recommendations to suit the inner bougie in you. Auction Rooms is guaranteed to be a lot busier than some of our earlier recommendations, however this is for the people who love to be around other people, for the people who love the energy and zest that you can get from being in the centre of it all.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Auction Rooms, out of all the ones listed above, have the most vegan friendly options, ranging from sweet to savoury! Here at Stint, we personally recommend the Breakfast Board ($25) because sharing is caring, and especially at that price point? We highly recommend that you do.
                    </p>
                </div>
                <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                    <div class = "col-span-12 md:col-span-6">
                        <img src={require('./../../assets/Images/AuctionRooms1.png')} class = "w-full"/>
                    </div>
                    <div class = "col-span-12 md:col-span-6 h-full flex items-end">
                        <p class = "caption text-caption-mobile md:text-caption text-gray-500">
                            Credit: broadsheet.com.au
                        </p>
                    </div>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        With Western brunch offerings and stellar coffee, Auction Rooms is a great way to kick off a study day with high energy and filling food. The atmosphere here is one that encapsulates what Melbourne is really all about: fun, friendly, and fresh!
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Address: 103-107 Errol St, North Melbourne VIC 3051
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Opening Hours
                    </p>
                    <div class = "grid grid-cols-3 w-full md:w-3/6">
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Monday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">7:00am-4:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Tuesday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">7:00am-4:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Wednesday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">7:00am-4:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Thursday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">7:00am-4:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Friday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">7:00am-4:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Saturday</div><div class = "col-span-2 paragraph-two text-paragraph-two-mobile md:text-paragraph-two">7:30am-4:00pm</div>
                        <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">Sunday</div><div class = "col-span-2  paragraph-two text-paragraph-two-mobile md:text-paragraph-two">7:30am-4:00pm</div>
                    </div>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default StudyPlaces;