import data from "./../../data/jobBoardFilters.json";

const Filters = (addIndustry, addJobType, addPrReq, deleteIndustries, deleteJobTypes, deletePrReqs, searchPress, filters) => {
    const industries = data["industries"];
    const jobType = data["jobTypes"];
    const prReq = data["prReqs"] 
    
    function clear(){
        deleteIndustries();
        deleteJobTypes();
        deletePrReqs();
        searchPress(
        {
        "search": filters.search,
        "locations": filters.locations,    
        "industries": [],
        "jobTypes": [],
        "prReqs": [],
        })
    }
    return (
        <div class = "sticky top-5 h-screen">
            <p class = "text-left paragraph-two-bold">
                Filters
            </p>
            <hr class = "mt-5 border-stone-200 border-[1.4px] "></hr>
            <div class = "overflow-y-scroll scrollbar h-4/6 mt-3">
                <p class = "text-left paragraph-one-bold mt-1">
                    INDUSTRY
                </p>
                <div class = "grid grid-cols-1 pt-4">
                    {industries.map((industry) => 
                        <div class="flex items-center mb-4">
                            <label class="paragraph-four">
                                <input class="industryChecks w-4 h-4 text-secondary-coral focus:ring-0 bg-white border-gray-300 rounded" type="checkbox" name="checkbox" onClick = {() => addIndustry(industry)} value={industry}/> 
                                <span class = "ml-2">
                                    {industry}
                                </span>
                            </label>
                        </div>
                    )}
                </div>
                <p class = "text-left paragraph-one-bold mt-7">
                    JOB TYPE
                </p>
                <div class = "grid grid-cols-1 pt-4">
                    {jobType.map((jobType) => 
                        <div class="flex items-center mb-4">
                            <label class="paragraph-four">
                                <input class="jobTypesChecks w-4 h-4 text-secondary-coral focus:ring-0 bg-white border-gray-300 rounded" type="checkbox" name="checkbox" onClick = {() => addJobType(jobType)} value={jobType}/> 
                                <span class = "ml-2">
                                    {jobType}
                                </span>
                            </label>
                        </div>
                    )}
                </div>
                {/* <p class = "text-left paragraph-one-bold mt-7">
                    PR REQUIREMENT
                </p>
                <div class = "grid grid-cols-1 pt-4 ">
                    {prReq.map((req) => 
                        <div class="flex items-center mb-4">
                            <label class="paragraph-four">
                                <input class="prReqsChecks w-4 h-4 text-secondary-coral focus:ring-0 bg-white border-gray-300 rounded" type="checkbox" name="checkbox" onClick = {() => addPrReq(req)} value={req}/> 
                                <span class = "ml-2">
                                    {req}
                                </span>
                            </label>
                        </div>
                    )}
                </div> */}
            </div>
            <div class = "w-full pt-12 pr-4">
                <button onClick = {() => searchPress(filters)} class = "px-8 py-3 button rounded-3xl bg-secondary-coral text-white">
                    Search
                </button>
            </div>
            <div class = "w-full pt-5 pr-4">
                <button onClick = {() => clear()} class = "w-full h-full">
                    <span class = "clear text-stone-400">
                        Clear Advanced Filters
                    </span>
                </button>
            </div>
        </div>
    );
  };

export default Filters;