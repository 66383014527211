const WhyStint = () => {
    return (
        <div>
            <p class = "paragraph-one text-paragraph-one-mobile md:text-paragraph-one text-stone-500">
                Why Stint?
            </p>
            <p class = "section-heading text-section-heading-mobile md:text-section-heading text-primary-navy pt-5 pb-5">
                A unique alternative to traditional job boards
            </p>
            <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two">
                Harness the power of Australia's largest international talent pool to amplify your success.
            </p>
            <div class = "grid grid-cols-4 md:grid-cols-12 gap-y-5  md:gap-x-5 pt-5 md:pt-16">
                <div class = 'col-span-4 px-12 h-full bg-white shadow-xl rounded-2xl'>
                    <p class = "paragraph-one-bold text-paragraph-one-mobile md:text-paragraph-one py-8">
                        Pipeline  of diverse, multilingual talent
                    </p>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two pb-8">
                        Access a curated network of international candidates
                    </p>
                </div>
                <div class = 'col-span-4 px-12 h-full bg-white shadow-xl rounded-2xl'>
                    <p class = "paragraph-one-bold text-paragraph-one-mobile md:text-paragraph-one py-8">
                        Improve Diversity, Equity and Inclusion (DEI)
                    </p>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two pb-8">
                        Hire multilingual, culturally diverse candidates
                    </p>
                </div>
                <div class = 'col-span-4 px-12 h-full bg-white shadow-xl rounded-2xl'>
                    <p class = "paragraph-one-bold text-paragraph-one-mobile md:text-paragraph-one py-8">
                        Simple and cost-effective listing options 
                    </p>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two pb-8">
                        Reduce time and money spent on recruitment
                    </p>
                </div>
            </div>
        </div>    
    );
  };

export default WhyStint;