import { useEffect, useState } from "react";

export const JobBoardHook = () => {   
    // Define state to store job data an ddefault return
    const [jobsData, setJobsData] = useState([]);

    // API Calling on page render
    // const READ_ONLY_WEBSITE = "https://api.airtable.com/v0/appYrjNdIk1k706bz/Stint%20Job%20Board?sort%5B0%5D%5Bfield%5D=Date+Created&sort%5B0%5D%5Bdirection%5D=desc"
        const READ_ONLY_WEBSITE = "https://api.airtable.com/v0/appYrjNdIk1k706bz/Stint%20Job%20Board?sort%5B0%5D%5Bfield%5D=Premium&sort%5B0%5D%5Bdirection%5D=asc&sort%5B1%5D%5Bfield%5D=Date+Created&sort%5B1%5D%5Bdirection%5D=desc"
    useEffect(() => {
        const fetchJobs = async() => {
            await fetch(READ_ONLY_WEBSITE,{
                headers: {
                    'Authorization': "Bearer patSLiUvelDglZp8c.77b037b9fba784c44d232e575d42098524ec4306435b85c5fa027261edbd2df8",
                }
            })
            .then((res) => res.json())
            .then((data) => {setJobsData(data.records)})
            .catch((error) => {
                console.log(error);
            });
        }
        fetchJobs();
    }, []);

    // Return job data
    return (jobsData);
  }