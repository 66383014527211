const Service = (image, title, text1, text2, button, buttonLink) => {
    return (
        <div class = "w-full z-10 text-left">
            <p class = "text-center md:text-left sub-heading text-sub-heading-mobile md:text-sub-heading">
                {title}
            </p>
            <img loading="lazy" src={image} class = "md:hidden w-full py-6"/>
            <p class = "paragraph-one text-paragraph-one-mobile md:text-paragraph-one pb-6 md:py-12">
                {text1}
            </p>
            <p class = "paragraph-one text-paragraph-one-mobile md:text-paragraph-one">
                {text2}
            </p>
            <button class = "mt-8 md:mt-16 rounded-full text-button-mobile md:text-button button w-fit px-8 py-3 bg-primary-purple text-white">
                <a href = {buttonLink}>
                    {button}
                </a>
            </button>
        </div>  

    );
  };

export default Service;