import Landing from '../../components/studentHome/landing';
import Stats from '../../components/studentHome/stats';
import SellingPoint from '../../components/studentHome/sellingPointCard';
import sellingPoint1 from "./../../assets/Images/sellingPoint1.png"
import sellingPoint2 from "./../../assets/Images/sellingPoint2.png"
import sellingPoint3 from "./../../assets/Images/sellingPoint3.png"
import FAQ from '../../components/FAQs/FAQ';
import Service from '../../components/studentHome/service';
import service1 from "./../../assets/Images/Service1-2.png"
import service2 from "./../../assets/Images/Service2-2.png"
import service3 from "./../../assets/Images/Service3-2.png"
import StudentTestimonial from '../../components/Testimonial/StudentTestimonial';
import Blogs from '../../components/studentHome/blogs';

const StudentLanding = () => {
    function jobBoardScroll(){
        const element = document.getElementById("jobBoard");
        if (element) {
            element.scrollIntoView({behavior: 'smooth' });
        }
    }

    function communityScroll(){
        const element = document.getElementById("community");
        if (element) {
            element.scrollIntoView({behavior: 'smooth' });
        }
    }

    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>    
        <div class = "bg-secondary-blue px-10">
            {Landing(jobBoardScroll, communityScroll)}
        </div>  
        <div>
            <Stats></Stats>
        </div>
        <div class = "backgroundS md:px-10 px-4 py-12 md:py-24">
            <div>
                <p class = "paragraph-one text-paragraph-one-mobile md:text-paragraph-one text-stone-400">
                    Why Stint?
                </p>
                <p class = "text-primary-purple text-section-heading-mobile md:text-section-heading section-heading pt-5">
                    Normal job platforms aren’t built for international students.
                </p>
                <p class = "text-primary-purple text-section-heading-mobile md:text-section-heading section-heading pb-5">
                    But we are.
                </p>
                <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two text-primary-purple pb-5">
                    In Australia, building a career as an international student can feel impossible. We’re here to change that. 
                </p>
            </div>
            <div class = "grid md:grid-cols-3 px-8 md:px-0 lg:px-14 md:gap-x-10 xl:gap-x-24 pt-10 gap-y-10">
                <div>
                    {SellingPoint(sellingPoint1, "Jobs without citizenship or PR requirements", "Streamline your job search")}
                </div>
                <div>
                    {SellingPoint(sellingPoint2, "Roles relevant to your university degree", "Put your lectures into practice")}
                </div>
                <div >
                    {SellingPoint(sellingPoint3, "Free for international students", "Tailored support at no cost")}
                </div>
            </div>
            <div class = "pl-16 lg:pl-32 pr-12 lg:pr-24 pt-24 md:pt-24 lg:pt-24">
                {/* <p class = "paragraph-one text-paragraph-one-mobile md:text-paragraph-one text-stone-400">
                    How we help
                </p> */}
                <p class = "text-primary-purple text-section-heading-mobile md:text-section-heading section-heading md:pt-5">
                    Our free services for international students
                </p>
                <div class = "grid grid-cols-6 md:grid-cols-12 pt-12 gap-y-12 md:pt-10 md:gap-y-24">
                    <div id = "jobBoard" class = "flex items-center h-full col-span-6 md:pl-12 md:pr-32">
                        {Service(service1, "Job Board", "Tired of only seeing jobs that require citizenship and PR?", "Find up-to-date job opportunities from Australian companies  that want international talent.", "Find jobs", "/job-board")}
                    </div>
                    <div class = "hidden md:grid col-span-6">
                        <img src={require('./../../assets/Images/Service1-2.png')} class = "w-full"/>
                    </div>
                    <div class = "hidden md:grid col-span-6">
                        <img src={require('./../../assets/Images/Service2-2.png')} class = "w-full"/>
                    </div>
                    <div id = "community" class = "pt-10 md:pt-0 flex items-center  col-span-6 md:pl-24">
                        {Service(service2, "Online Community", "Interested in building your personal and professional network?", "Join our online community to build connections with peers, employers and access job resources and support. ", "Join our community", "https://docs.google.com/forms/d/e/1FAIpQLScEg-PTGW04bk_ctv62nwj0t2gj_ZBI7BVQj82L4lQtb5AI2g/viewform")}
                    </div>
                    <div class = "pt-10 md:pt-0 flex items-center md:pl-12 col-span-6 md:pr-32">
                        {Service(service3, "Career Events", "Ready to land your dream role?", "Attend our workshops that help improve your resumes, cover letters, networking skills, and more.", "See upcoming events", "https://ticketing.humanitix.com/tours/stint-s-events")}
                    </div>
                    <div class = "hidden md:grid col-span-6">
                        <img src={require('./../../assets/Images/Service3-2.png')} class = "w-full"/>
                    </div>
                </div>
            </div>
        </div>
            <StudentTestimonial/>
        <div class = "px-8 md:px-24 py-10 md:pt-20 md:pb-5">
            <Blogs/>
        </div>
        <div class = "pb-5">
            <FAQ type="student"/>
        </div>
        <div class = "bg-secondary-blue py-5">
            <p class = "pt-20 section-heading text-section-heading-mobile md:text-section-heading text-primary-purple">
                Want to learn more?
            </p>
            <p class = "px-8 py-4 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">
                Have questions on how we can support you to land your dream role? Chat with the team! 
            </p>
            <button class = "mb-12 mt-10 bg-white px-3 py-2 rounded-full">
            <a href = "https://airtable.com/appyT1YN8YDfocPTw/shrLi8VMRhMEWRpcd">
                <p class = "button text-button-mobile md:text-button text-black text-center">
                    Get in touch
                </p>
            </a>
            </button>
        </div>
    </div>
     

    );
  };

export default StudentLanding;