import BlogCards from "../Blogs/blogCards";

const Blogs = () => {

    return (
        <div class = "">
            <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two text-left">
                Stay informed, stay inspired
            </p>
            <div class = "grid grid-cols-4 text-left py-5 gap-y-3">
                <div class = "col-span-4 md:col-span-3">
                    <p class = "section-heading text-section-heading-mobile md:text-section-heading text-primary-purple">
                        Explore the Stint blog
                    </p>
                </div>
                <div class = "pb-5 h-full col-span-2 md:col-span-1 text-center grid md:place-items-end">
                    <a href = "/blogs" class = "bg-secondary-blue text-button-mobile md:text-button button rounded-full px-4 py-2 mb-3 md:mb-0">
                        Browse all articles
                    </a>
                </div>
            </div>
            {BlogCards("blogs", 3)}
        </div>    
    );
  };

export default Blogs;