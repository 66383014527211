import React from 'react';
import backgroundImage from '../../assets/Images/Comma_cropped.png';

const TestimonialCard = ({ title, text, author, position, image }) => {
  return (
    <div 
    className="mx-2 md:mx-10 py-10 px-5 sm:px-10 md:px-10 bg-primary-white rounded-xl shadow-lg flex flex-col items-start min-h-[34rem] sm:min-h-[45rem] md:min-h-[23rem]" 
     style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: '90% auto', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}
     >
      <h3 className="paragraph-one-bold text-paragraph-one-mobile md:text-paragraph-one text-left mb-2">{title}</h3>
      <p className="paragraph-two text-paragraph-two-mobile md:text-paragraph-two text-left overflow-hidden">{text}</p>
      <div className="grid gap-y-2 md:flex md:items-center md:justify-end mt-auto md:ml-auto">
        <p className="paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two text-left md:text-right">{author}, {position} </p> 
        <img
          className="w-14 h-14 sm:w-12 sm:h-12 md:w-13 md:h-13 lg:w-14 lg:h-14 2xl:w-20 2xl:h-20 rounded-full md:ml-4"
          src={image}
          alt={author}
        />
      </div>
    </div>
  );
};

export default TestimonialCard;
