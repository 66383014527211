import React from 'react'
import useView from '../../hooks/viewHooks';
import { useEffect } from 'react';


const NavbarSwitch =() =>{
    let {studentView, changeView} = useView();
    
    return (
        <button id = "switch" onClick={changeView} class = "border-2 lg:border-4 border-primary-purple flex place-items-center bg-primary-purple rounded-full">
            <span class = {`${studentView? "bg-white text-black":"bg-primary-purple text-white"} rounded-full px-3 button text-button-mobile md:text-button py-1 `}>Students</span>
            <span class = {`${!studentView? "bg-white text-black":"bg-primary-purple text-white"} rounded-full px-3 button text-button-mobile md:text-button py-1`}>Employers</span>
        </button>
    )
}
export default NavbarSwitch
