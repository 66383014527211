export default function Newsletter() {
    return(
        <div class="w-full">
            <style>
                @import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400&display=swap');
            </style>
            <div class = "grid grid-cols-12 text-left">
                <div class = "col-span-9 relative">
                    <p class = "sub-heading text-white absolute bottom-0">
                        Jobs for international students into your inbox. 
                    </p>
                </div>
                <div class = "col-span-3">
                    <div class = "h-full w-full text-left">
                        <button class = "bg-secondary-blue hover:bg-secondary-steel text-black button py-2 px-4 rounded-full md:mb-2 mt-3">
                            <a href = "https://stintcommunity.beehiiv.com/subscribe">
                                Subscribe
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    );

}