import Title from "../../components/Blogs/title";
import NathanAuthor from "../../assets/Images/NathanAuthor.jpg"
import { useEffect } from "react";
const Sponsors = () => {
    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/blogs" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/sponsors.jpg')} class = "object-contain w-full h-full"/>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Hiring", 
                        "Wondering What Companies Can Sponsor in Australia? Check Out the List of Accredited Sponsors in 2024", 
                        "As an international student in Australia, finding companies that accept and sponsor international students can be challenging. To make your job search easier, we reached out to the Department of Home Affairs and obtained the list of Accredited Sponsors in Australia for 2024. These companies are approved to sponsor individuals, including international students and graduates, for various visa programs, providing a potential pathway to obtaining a work visa and permanent residency.",
                        "Nathan Lee", 
                        NathanAuthor, 
                        "13th August 2024")}
                </div>
                <div class = "pt-10 col-span-12 md:hidden w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/sponsors.jpg')} class = "object-contain w-full h-full"/>
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        Accredited sponsors are companies that have been approved by the Australian Government to sponsor foreign workers for various visa programs. These companies meet specific requirements, ensuring they are legitimate and have a genuine need for skilled workers.
                    </p>
                </div>
                <div>
                    <p class = "pt-5 sub-heading text-sub-heading-mobile md:text-sub-heading md:w-5/6">
                        List of Accredited Sponsors
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        The table below categorises accredited sponsors by industry, making it easier for you to identify potential employers in your field of interest. Use this list to target your job applications and increase your chances of securing sponsorship.
                    </p>
                </div>
                <div>
                    <table class="table-auto md:w-5/6 border border-slate-400">
                        <thead>
                            <tr>
                                <th class = "border border-slate-300 px-1 paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two">Industry</th>
                                <th class = "border border-slate-300 px-1 paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two">Company Name</th>
                            </tr>
                        </thead>
                        <tr>
                            <td class = "border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Aerospace</td>
                            <td class = "border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Airbus Australia Pacific</td>
                        </tr>
                        <tr>
                            <td class = "border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Aerospace</td>
                            <td class = "border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">BAE Systems Australia</td>
                        </tr>
                        <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Aerospace</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Boeing Defence Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Aerospace</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Lockheed Martin Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Aerospace</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Raytheon Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Aerospace</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Thales Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Agriculture</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Nutrien Ag Solutions</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Agriculture</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Syngenta Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Arts and Culture</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Australian Chamber Orchestra</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Automotive</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Rolls-Royce Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Automotive</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Tesla Motors Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Automotive</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Toyota Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Automotive</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Volvo Group Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">ANZ Banking Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Bank of Queensland</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Barclays Bank PLC</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Bendigo and Adelaide Bank</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">BlackRock Investment Management</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Citigroup Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Commonwealth Bank of Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Deutsche Bank Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">HSBC Bank Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">JP Morgan Chase Bank</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Macquarie Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Morgan Stanley Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">National Australia Bank</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Rabobank Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Royal Bank of Canada Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Banking and Finance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Westpac Banking Corporation</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Biotechnology</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Agilent Technologies Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Biotechnology</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">CSL Limited</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Biotechnology</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Illumina Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Broadcasting</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">ABC (Sydney)</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Broadcasting</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Australian Broadcasting Corporation</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Chemicals</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">BASF Australia Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Chemicals</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Ecolab Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Chemicals</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Orica Australia Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Construction</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Acciona Construction Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Construction</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Brookfield Multiplex</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Construction</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Hill International</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Construction</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">John Holland Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Construction</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Lendlease Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Construction</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">McConnell Dowell Constructors</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Consumer Goods</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Reckitt Benckiser Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Consumer Goods</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Unilever Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Consulting</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">McKinsey & Company</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Consulting and IT</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Accenture Australia Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Consulting and IT</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Capgemini Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Academy of Entrepreneurs</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">ACT Education Directorate</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Affinity Education Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Australian Catholic University</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Australian National University</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Edith Cowan University</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">James Cook University</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">La Trobe University</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Monash University</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Murdoch University</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Queensland Government</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Royal Melbourne Institute of Technology (RMIT)</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">The University of Melbourne</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">University of New South Wales</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">University of Queensland</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">University of Sydney</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">University of Technology Sydney</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Education and Training</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">University of Western Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">ActewAGL Distribution</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">AEMO (Australian Energy Market Operator)</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">AGL Energy Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">APA Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Baker Hughes</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">BP Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Chevron Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">ConocoPhillips Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">E-Green Electrical</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">ExxonMobil Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">GE Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">National Grid Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Origin Energy</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Santos Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Schneider Electric Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Energy</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Shell Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Environmental Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Veolia Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Facilities Management</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Assetlink Services</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Facilities Management</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Spotless Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Food and Beverage</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Diageo Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Food and Beverage</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Lion Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Food and Beverage</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Mars Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Food and Beverage</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">PepsiCo Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Food and Beverage</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">SunRice</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Food and Beverage</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">The Kraft Heinz Company Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Food Production</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Bega Cheese Limited</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Adelaide City Council</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Australian Bureau of Statistics</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Australian Federal Police</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Australian Financial Security Authority</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Australian Maritime Safety Authority</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Australian Nuclear Science and Technology Organisation</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Australian Prudential Regulation Authority</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Australian Securities and Investments Commission</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Australian Taxation Office</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Australian Transaction Reports and Analysis Centre</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Northern Territory Government</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Queensland Government</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Sydney Water</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Austroads</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Government</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Federal Reserve Bank of Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Healthcare</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">ACH Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Healthcare</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Aspen Medical</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Healthcare</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Australian Unity</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Insurance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">AIA Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Insurance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Allianz Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Insurance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Aviva Australia Holdings Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Insurance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">AXA XL</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Insurance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Bupa Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Insurance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Gallagher Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Insurance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Medibank Private</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Insurance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Mercer Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Insurance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">QBE Insurance Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Insurance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Suncorp Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Insurance</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Zurich Financial Services Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">ACP Computer Support</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">ADP Employer Services</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Adacel Technologies Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Advanced Mobility Analytics Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Adobe Systems Software Ireland</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Amazon Web Services</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Amgen Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Apple Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Atlassian</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Avery Dennison Materials Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Avanade Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Cisco Systems Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Cubic Transportation Systems</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Deloitte Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Dimension Data Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">DXC Technology Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">eBay Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Electrolux Home Products</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Ericsson Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Ernst & Young Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Esri Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Facebook Australia Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Fuji Xerox Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Fujitsu Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Garmin Australasia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Hewlett-Packard Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Hitachi Australia Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Honeywell Limited</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Hudson Global Resources</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IBM Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Infosys Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Intel Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Leidos Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">LinkedIn Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Microsoft Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Motorola Solutions Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">NEC Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">NTT Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Oracle Corporation Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Panasonic Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">PayPal Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Red Hat Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Salesforce.com Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Samsung Electronics Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">SAP Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Siemens Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Singtel Optus Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">SITA Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Southern Cross Austereo</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Telstra Corporation</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Uber Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">VMware Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Vodafone Hutchison Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Western Digital Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Xero Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Yahoo Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">IT Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Zebra Technologies</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Legal Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">White & Case LLP</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Manufacturing</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Arup Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Manufacturing</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Boral Limited</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Manufacturing</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">GE Mining</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Manufacturing</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Nissan Motor Co. Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Manufacturing</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Orora Limited</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Manufacturing</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Philip Morris International</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Media</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">News Corp Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Media</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Southern Cross Austereo</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Medical Devices</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Stryker Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Mining</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Anglo American</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Mining</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">BHP Billiton</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Mining</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Newcrest Mining</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Mining</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Rio Tinto</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Mining</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">South32 Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Pharmaceuticals</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Amgen Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Pharmaceuticals</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">AstraZeneca Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Pharmaceuticals</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Bristol-Myers Squibb Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Pharmaceuticals</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">GlaxoSmithKline Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Pharmaceuticals</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Merck Sharp & Dohme Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Pharmaceuticals</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Novartis Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Pharmaceuticals</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Pfizer Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Pharmaceuticals</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Roche Products Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Professional Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">KPMG Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Professional Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Manpower Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Professional Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Mercer Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Professional Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">PwC Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Professional Services</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Turner & Townsend</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Real Estate</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">CBRE Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Real Estate</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">JLL Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Retail</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Myer Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Retail</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Westfield Corporation</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Retail</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Woolworths Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Telecommunications</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Telstra Corporation Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Transport and Logistics</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Airbus Australia Pacific</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Transport and Logistics</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Brambles Limited</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Transport and Logistics</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">FedEx Express Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Transport and Logistics</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Linfox Pty Ltd</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Transport and Logistics</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Maersk Line Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Transport and Logistics</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Qantas Airways</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Transport and Logistics</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Sydney Airport Corporation</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Transport and Logistics</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Toll Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Transport and Logistics</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Transurban Group</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Transport and Logistics</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">UPS Australia</td>
                            </tr>
                            <tr>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Utilities</td>
                                <td class="border border-slate-300 px-1 paragraph-three text-paragraph-three-mobile md:text-paragraph-three">Sydney Water</td>
                            </tr>
                    </table>
                    <p class = "paragraph-four text-paragraph-four-mobile md:text-paragraph-four text-gray-600">Source: Department of Home Affairs, as at 31 July 2024</p>
                </div>
                <div>
                    <p class = "pt-5 sub-heading text-sub-heading-mobile md:text-sub-heading md:w-5/6">
                        Tips for your job applications
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        1. The next step is to keep an eye out for open roles and submit a job application, here are 5 tips to get you started:
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        2. Connect with professionals working at these companies through LinkedIn or industry events. Building a network can provide insights and increase your chances of getting noticed.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        3. Customise your resume and cover letter for each application, highlighting your skills and experiences relevant to the job and industry.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        4. Be well-prepared for interviews. Understand the company’s needs and be ready to discuss how you can contribute to their success.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        5. Stay updated with industry trends and enhance your skills through courses, workshops, and certifications.
                    </p>
                </div>
                <div>
                    <p class = "pt-5 paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        Share this blog with your fellow international students to help them on their journey towards finding a job in Australia!
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        For international students, join Stint’s free online community <a class = "text-blue-400 hover:text-blue-700" href = "https://docs.google.com/forms/d/e/1FAIpQLScEg-PTGW04bk_ctv62nwj0t2gj_ZBI7BVQj82L4lQtb5AI2g/viewform">here</a> and check out our job board which exclusively lists roles without Australian citizenship or PR requirements <a class = "text-blue-400 hover:text-blue-700" href = "/job-board">here</a>.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        For employers, list your roles on Stint’s job board <a class = "text-blue-400 hover:text-blue-700" href = "https://airtable.com/appbzFREDaka6w9wq/shrqPnKyf7baIvCMs">here</a>.
                    </p>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default Sponsors;