import "preline/preline";
import Locations from "./locations";

const SearchBarMobile = (locations, setLocations, setSearch, filters, searchPress, deleteSearch) => {
    const handleSearch = (event) => {
        setSearch(event.target.value);
      };

    function clear(){
        deleteSearch();
        setLocations([]);
    }
    return (
        <div class = "bg-white rounded-3xl relative z-40 grid grid-cols-12 gap-x-0 drop-shadow-md border-[0.5px] border-stone-300">

            <div class = "col-span-9 grid grid-cols-12 py-1">
                <div class = "col-span-1 grid place-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full pl-4 pr-2" fill="none" viewBox="0 0 24 24" stroke="dimGray">
                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </div>
                <div class = "col-span-11">
                    <input
                    class="h-full w-full paragraph-four text-paragraph-four-mobile border-0 text-gray-700 pr-2 focus:ring-0"
                    type="text"
                    id="search"
                    placeholder="Job title or keywords"
                    onChange = {handleSearch}/> 
                </div>
            </div>
            <div class = "col-span-3 h-full w-full grid grid-cols-12">
                <div class = "pr-1 col-span-12 h-full py-1 w-full ">
                    <button onClick = {() => searchPress(filters)} class = "w-full h-full button text-button-mobile rounded-3xl bg-secondary-coral text-white">
                        Search
                    </button>
                </div>
            </div>
        </div>
    );
  };

export default SearchBarMobile;