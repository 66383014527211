import Title from "../../components/Blogs/title";
import Paul from "../../assets/Images/Paul.jpg";
import hrefLink from "../../components/General/hrefLink";

const Skills = () => {
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/insights" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/skills.png')} class = "object-contain w-full h-full"/>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Hiring", 
                        "The hidden skills and qualities of International students", 
                        "When working with International students, as a student advisor, I attend a lot of Employment expos and am constantly astounded at the lack of companies wishing to take on recently graduated International students.",
                        "Paul Florentine", 
                        Paul, 
                        "5th September 2024")}
                </div>
                <div class = "pt-10 col-span-12 md:hidden w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/skills.png')} class = "object-contain w-full h-full"/>
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-6 grid">
                <div class = "grid gap-y-4">
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    Whilst I understand that employers may shy away from a Temporary resident visa holder as they are unsure of their long-term future, I suggest that in doing so, these employers are missing out on exactly the type of skills they are looking for from a graduate employee.
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    Larger companies, such as Deloitte, Ernst and Young, Telstra, KPMG, PwC and even BHP have all started realising this…so what are the rest missing out on?
                    </div>
                </div>
                <div class = "grid gap-y-4">
                    <div class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Problem-solving abilities
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Imagine moving to a new country after never having been away from family. Sounds daunting? Now imagine you must manage this move in your second or third language and in an unfamiliar culture. Oh, by the way, you’ll be starting a full-time university course in a few weeks in a completely foreign academic system…and don’t forget, you’ll need to find work as soon as possible to pay for that course and / or help support your family.
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Many international students face this exact set of problems within their first month of being in Australia. If their resume has reached your desk, it is because they have successfully navigated this unique set of problems along with many more that arise during the course of their studies.
                    </div>
                </div>
                <div class = "grid gap-y-4">
                    <div class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    Time management and organisation 
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    I often tell students that they are often getting a side degree in Project Management when they study and that the project is their life! While many students take part-time jobs while studying, almost all International students have to. In my experience, International students are generally more involved in University committees, clubs, student unions and volunteering. I also oversee a lot of additional study support programs that are usually full of international students.
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    The level of time management capabilities possessed by a successful international student is amazingly high.
                    </div>
                </div>
                <div class = "grid gap-y-4">
                    <div class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    Communication skills
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    Whilst it may seem obvious that most international students speak more than one language (and often more than two), what may be less obvious is their heightened  intercultural communication skills. International students have to communicate across different cultures during university life. These students have to navigate Australian culture, but they are also usually grouped with International students from other cultures throughout their degree as well. This usually leads to the development of strong Intercultural communication skills, which is important for most companies in a globalised business environment.
                    </div>
                </div>
                <div class = "grid gap-y-4">
                    <div class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    Adaptability and flexibility
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    For those outside of the education sector it can be difficult to imagine how adaptable an international student has to be to take their skills, knowledge and learning style and apply them to a completely different education system.
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    However, achieving this feat leaves a lasting impact on the ability of the student to be flexible in their approach to tasks and adapt to new environments. If employers are looking for someone who can adapt quickly and hit the ground running, they may best consider an International student graduate.
                    </div>
                </div>
                <div class = "grid gap-y-4">
                    <div class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Leadership qualities and Emotional Intelligence
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    The truth for many International students is that they are pioneers for their family. Many are the first in their family to attend, while almost all bring with them family responsibilities.
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    For some, this may mean having to support their family (who may have taken out a loan to fund their study) while they study and support themselves. Others may have the responsibility of trying to set up a life here in Australia as a foundation for their family.
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    Needless to say, there is a lot on these young people’s shoulders and successful completion of their studies, shows that they are able withstand considerable pressure while thriving.
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    Sought after skills, such as resilience and self-management are required for these students to succeed.
                    </div>
                </div>
                <div class = "pt-5">
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    In short, employers should not limit their opportunities by aiming job advertisements to PR holders and citizens. This is not a matter of social consciousness, or even the ‘give ‘em a fair go’ Aussie mentality. The business case for the benefits of hiring International student graduates is clear. By nature, these graduates have overcome hurdles that others have not had and therefore developed skills in a way that very few local students have. Missing out on hiring these graduates simply doesn’t make business sense.
                    </div>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default Skills;