import Title from "../../components/Blogs/title";
import will from "../../assets/Images/Will.jpg";
import hrefLink from "../../components/General/hrefLink";

const Why = () => {
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/insights" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/Why.png')} class = "object-contain w-full h-full"/>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Hiring", 
                        "Why Australian Employers Should Hire International Students and Graduates", 
                        "In an increasingly globalised world, Australian businesses are challenged to stay competitive, innovative, and responsive to the needs of diverse markets. One significant, yet often overlooked, opportunity lies in tapping into the wealth of talent provided by international students and graduates. Despite some lingering misconceptions and hesitations, the benefits of hiring these individuals are profound and far-reaching.",
                        "Will Phelan", 
                        will, 
                        "5th September 2024")}
                </div>
                <div class = "pt-10 col-span-12 md:hidden w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/Why.png')} class = "object-contain w-full h-full"/>
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-6 grid">
                <div class = "grid gap-y-4">
                    <div class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        The Current Perception: A Mixed Bag
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    A recent {hrefLink("report", "https://www.deakin.edu.au/__data/assets/pdf_file/0010/365194/international-graduates-employment.pdf")} by Deakin University highlighted a persistent hesitancy among Australian employers to hire international graduates, particularly those on temporary visas. Concerns about language proficiency, visa restrictions, and job commitment without permanent residency status are common misperceptions. These biases overlook the immense potential that international talent brings to the workforce.
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    However, it is not all doom and gloom. Recent polling commissioned by {hrefLink("Universities Australia", "https://universitiesaustralia.edu.au/")} paints a more optimistic picture. The data reveals that 61% of Australians recognise the contributions of international students to economic growth, and 68% agree that these students fill critical skills gaps. Additionally, 75% believe international graduates who remain in Australia significantly boost the workforce and economy. This shift in public opinion suggests that the time is ripe for employers to reassess their hiring practices and embrace the value of international talent.
                    </div>
                </div>
                <div class = "grid gap-y-4">
                    <div class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Bridging the Gap: Government and Industry Initiatives
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Recognising the need to bridge the gap between international graduates and employment opportunities, various state government initiatives have launched. For example, Study Melbourne’s Victorian International Education Recovery {hrefLink("Plan", "https://www.studymelbourne.vic.gov.au/industry/news-and-updates/victorias-international-education-recovery-plan-2025")} is a proactive effort to address employment barriers and promote post-study pathways for global talent. The Victorian Government’s Commitment to Action demonstrates a collaborative approach, working with employers, industry bodies, and education providers to facilitate smoother transitions from study to work for international students.
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        These initiatives are a step in the right direction, but more needs to be done at the grassroots level. As someone working in career services within higher education, I have seen firsthand the challenges international students face in securing employment in their fields of expertise. The solution is not as simple as telling employers to hire more international graduates—it requires a fundamental shift in mindset and strategy.
                    </div>
                </div>
                <div class = "grid gap-y-4">
                    <div class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        The Power of Work Integrated Learning
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    One area where we have seen considerable success is through Work Integrated Learning (WIL) programs, such as internships and placements. These opportunities allow international students to gain relevant work experience, demonstrate their capabilities, and build relationships with potential employers. For businesses, WIL programs provide a low-risk way to assess talent, build a diverse talent pipeline, and enhance organisational diversity.
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    Moreover, it is important to dispel the myth that all international students are seeking permanent employment in Australia. {hrefLink("Research", "https://www.sbs.com.au/language/punjabi/en/article/should-international-students-in-australia-be-offered-clearer-permanent-residency-pathways/flzufdi8p")} indicates that fewer than 20% of international students ultimately become permanent residents. Most return to their home countries after completing their degrees, where they go on to successful careers. This statistic underscores the global nature of the talent pool and the potential for Australian businesses to establish international connections through their alumni networks.
                    </div>
                </div>
                <div class = "grid gap-y-4">
                    <div class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    The Strategic Advantages of Hiring International Talent
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    The benefits of hiring international students and graduates extend beyond filling immediate skills gaps. In a country as multicultural as Australia, having a workforce that reflects this diversity is crucial for businesses that serve both national and international markets. International employees bring unique perspectives, cultural insights, and language skills that can enhance customer service and tailor products and services to local contexts.
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    Furthermore, international talent contributes to a more innovative and resilient workplace. These individuals often possess a strong work ethic, adaptability, and a willingness to go the extra mile—traits that are invaluable in today’s fast-paced business environment. By including international talent in your hiring strategy, you are not just filling a position—you are investing in the future growth and competitiveness of your business.
                    </div>
                </div>
                <div class = "grid gap-y-4">
                    <div class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    The Bottom Line
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    The call to hire more international students and graduates is not just about addressing talent shortages; it is about harnessing a strategic advantage that can propel Australian businesses forward in the global marketplace. The combination of diverse perspectives, enhanced cultural competence, and the ability to tap into international networks makes international hires a powerful asset.
                    </div>
                    <div class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    As public perception shifts and government initiatives gain momentum, now is the perfect time for Australian employers to overcome any lingering biases and fully embrace the value that international students and graduates bring to the table. The benefits are clear, the opportunities are vast, and the time to act is now.
                    </div>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default Why;