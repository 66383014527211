import React, { useEffect, useState } from 'react';


export default function LogoAndSocials() {
    const [activeIndex, setActiveIndex] = useState(0);
    const images = [
        require('../../assets/Images/demand.png'),
        require('../../assets/Images/telligent.png'),
        require('../../assets/Images/teresting.png'),
        require('../../assets/Images/ternational.png')
    ];
    const images2 = [
        require('../../assets/Images/demand2.png'),
        require('../../assets/Images/telligent2.png'),
        require('../../assets/Images/teresting2.png'),
        require('../../assets/Images/ternational2.png')
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <div>
            <div className="grid grid-cols-12 md:gap-y-5 gap-y-2">
                <div className="col-span-12 md:px-0 flex justify-center">
                    <img className="w-32 md:w-full h-auto" src={require('../../assets/Images/Stint_Logo_White_RGB_cropped.png')} alt="Logo" />
                </div>
            </div>
            <div className="grid grid-cols-12 pt-16 md:pt-5 pb-5 md:pl-0 pl-5">
                <div className="hidden md:block relative col-span-3 flex justify-end md:justify-center item-start">
                    <img className="w-full h-min max-h-16 md:absolute md:top-1/2 md:-translate-y-1/2 md:pb-4" src={require('../../assets/Images/in.png')} alt="In" />
                </div>
                <div className="md:hidden relative col-span-3 flex justify-end md:justify-center item-start">
                    <img className="w-10 h-auto max-h-16 pb-3" src={require('../../assets/Images/in.png')} alt="In" />
                </div>

                <div className="col-span-9 relative">
                    <div className="hidden md:block carousel-container w-full">
                        {images.map((src, index) => (
                            <div
                                key={index}
                                className={`carousel-slide ${index === activeIndex ? 'active' : ''}`}
                                style={{ transform: `translateY(${100 * (index - activeIndex)}%)` }}
                            >
                                <img className="carousel-image" src={src} alt={`Carousel ${index}`} />
                            </div>
                        ))}
                    </div>
                    <div className="md:hidden carousel-container w-48 sm:w-96 absolute bottom-0">
                        {images2.map((src, index) => (
                            <div
                                key={index}
                                className={`carousel-slide ${index === activeIndex ? 'active' : ''}`}
                                style={{ transform: `translateY(${100 * (index - activeIndex)}%)` }}
                            >
                                <img className="carousel-image absolute bottom-0" src={src} alt={`Carousel ${index}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-5 px-6 md:px-0 gap-x-3 pb-5 pt-3">
                <div className="col-span-1">
                    <a href="https://www.facebook.com/stintcommunity/">
                        <img className="social-icon" src={require('../../assets/Images/facebooklogo.png')} alt="Facebook" />
                    </a>
                </div>
                <div className="col-span-1">
                    <a href="https://www.instagram.com/stint.community/">
                        <img className="social-icon" src={require('../../assets/Images/instagramlogo.png')} alt="Instagram" />
                    </a>
                </div>
                <div className="col-span-1">
                    <a href="https://www.linkedin.com/company/stintcommunity/">
                        <img className="social-icon" src={require('../../assets/Images/linkedinlogo.png')} alt="LinkedIn" />
                    </a>
                </div>
                <div className="col-span-1">
                    <a href="https://www.tiktok.com/@stint.community">
                        <img className="social-icon" src={require('../../assets/Images/tiktoklogo.png')} alt="TikTok" />
                    </a>
                </div>
                <div className="col-span-1">
                    <a href="https://twitter.com/StintCommunity">
                        <img className="social-icon" src={require('../../assets/Images/xlogo.png')} alt="Twitter" />
                    </a>
                </div>
            </div>
        </div>
    );
}
