import React from 'react';
import Testimonial from './Testimonial';
import Bruce from "./../../assets/Images/Bruce.jpeg"
import Ken from "./../../assets/Images/Ken.jpg"
import Kaiven from "./../../assets/Images/Kaiven.png"
import Erica from "./../../assets/Images/Erika.png"

const StudentTestimonial = () => {
  const testimonials = [
    {
      title: "Support securing a job",
      text: "Stint helped me secure a job by creating an environment where I can have direct contact to employers and recruiters. I love how the moderators at Stint are very active and are always ready to answer members’ questions.",
      author: "Bruce Doan",
      position: "University of Melbourne",
      image: Bruce
    },
    {
      title: "Home away from home",
      text: "It was nice having a community of international students in Australia. It makes it feel like we’re not alone. You guys were even able to get a migration agent to offer free advice from time to time. Really nice!",
      author: "Ken Walter Sy",
      position: "Macquarie University",
      image: Ken
    },
    {
      title: "Stress-free job searching",
      text: "I highly recommend Stint to anyone looking for a stress-free and supportive job searching experience. The Stint team made every effort to maintain good communication and were always eager to provide assistance or advice if needed.",
      author: "Kaiven Ng",
      position: "Melbourne Polytechnic",
      image: Kaiven
    },
    {
      title: "Strong support and resources",
      text: "The resume reviewing was really helpful, I received insightful tips for improvement which helped me get past many screening stages of the application process! It has helped extensively and I’m really grateful for the edits and feedback.",
      author: "Erika Shi",
      position: "Deakin University",
      image: Erica
    }
  ];

  return (
    <Testimonial
      backgroundColor="bg-secondary-stone"
      title="Stint helps international students land jobs"
      description="Hear how Stint helps our members access support, tailored job opportunities, resources, and more."
      textColor="text-primary-purple"
      testimonials={testimonials}
    />
  );
};

export default StudentTestimonial;
