import { useState } from "react";

export const IndustriesHook = () => {   
    // Define state to store search state
    const [industries, setIndustries] = useState([]);

    // Create function to add
    function addIndustry(industry){
        const index = industries.indexOf(industry)
        // If it doesn't exists add it
        if (index == -1){
            setIndustries(oldIndustries => [...oldIndustries, industry])
        }
        // If it exists then remove it
        else{
            setIndustries(oldIndustries => oldIndustries.filter(item => item != industry));
        }
    }

    // Create function to clear
    function deleteIndustries(){
        setIndustries([])
        let checkBoxes = document.getElementsByClassName("industryChecks")
        for (let i = 0; i <checkBoxes.length; i++){
            let checkBox = checkBoxes[i];
            checkBox.checked = false;
        }
    }

    return {industries, addIndustry, deleteIndustries};
  }