import Value from "./value";
import community from './../../assets/Images/community.png'
import culture from './../../assets/Images/culture.png'
import courage from './../../assets/Images/courage.png'
import adaptability from './../../assets/Images/adaptability.png'

const OurValues = () => {
    return (
        <div>
            <p class = "text-section-heading-mobile md:text-section-heading section-heading text-primary-purple">
                Our values
            </p>
            <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two xl:mx-24 mx-5 text-primary-purple pt-5 md:pt-10">
                For <span class = "text-secondary-yellow">international students</span>
                , it's changing the Australian workforce one international student at a time.
            </p>
            <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two xl:mx-96 mx-5 text-primary-purple">
                For <span class = "text-secondary-yellow">employers</span>
                , it's the globalised talent pool right at your fingertips.
            </p>
            <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two xl:mx-96 mx-5 text-primary-purple">
                For <span class = "text-secondary-yellow">Stint</span>
                , it's inspiring diversity and inclusivity in you. 
            </p>
            <div class = "grid grid-cols-1 pt-10 px-8 md:grid-cols-4 gap-y-10 md:mx-5 md:gap-x-5 lg:mx-16 lg:gap-x-16 md:pt-24">
                <div>
                    {Value(community, "Community", "Harnessing the power of shared lived experiences to build a movement.")}
                </div>
                <div>
                    {Value(culture, "Culture", "Celebrating the cultural richness of our community that unites us. ")}
                </div>
                <div>
                    {Value(courage, "Courage", "Drawing on the grit and perseverance of international students.")}
                </div>
                <div>
                    {Value(adaptability, "Adaptability", "Empowering one another by harnessing the power of change.")}
                </div>

            </div>
        </div>
    );
  };

export default OurValues;