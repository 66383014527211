import "preline/preline";
import Locations from "./locations";

const SearchBar = (locations, setLocations, setSearch, filters, searchPress, deleteSearch, deleteIndustries, deleteJobTypes, deletePrReqs) => {
    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    const handleEnter = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            searchPress(filters);
        }
    };

    function clear(){
        deleteSearch();
        setLocations([]);
        deleteIndustries();
        deleteJobTypes();
        deletePrReqs();
        searchPress(
        {
        "search": "",
        "locations": [],
        "industries": [],
        "jobTypes": [],
        "prReqs": [],
        })
    }
    return (
        <div class = "bg-white rounded-3xl relative z-40 relative px-2 grid grid-cols-12 gap-x-1 drop-shadow-md border-[0.5px] border-stone-300">

            <div class = "col-span-6 grid grid-cols-12 border-r-2 border-2-stone-300 py-1">
                <div class = "col-span-1 grid place-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-full pl-4 pr-2" fill="none" viewBox="0 0 24 24" stroke="dimGray">
                        <path stroke-linecap="round" stroke-linejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </div>
                <div class = "col-span-11">
                    <input
                    class="h-full w-full text-lg caption border-0 text-gray-700 pr-2 focus:ring-0"
                    type="text"
                    id="search"
                    placeholder="Job title or keywords"
                    onKeyUp = {handleEnter}
                    onChange = {handleSearch}/> 
                </div>
            </div>
            <div class = "col-span-4 grid grid-cols-12  border-r-2 border-2-stone-300">
                <div class = "col-span-1 grid place-items-center">
                    <img src={require('./../../assets/Images/Icons/Location_Gray.png')} class = "lg:px-1.5 ml-1 lg:ml-2 w-full"/>
                </div>
                <div class = "col-span-11 grid ml-1 place-items-center">
                    {Locations(locations, setLocations)}
                </div>
            </div>
            <div class = "col-span-2 h-full w-full grid grid-cols-12">
                <div class = "col-span-6 pr-0 grid place-items-center h-full">
                    <button onClick = {() => clear()} class = "h-full grid place-items-center col-span-4">
                        <span class = "clear text-stone-400">
                            Clear
                        </span>
                    </button>
                </div>
                <div class = "col-span-6 h-full py-1 w-full ">
                    <button onClick = {() => searchPress(filters)} class = "w-full h-full button rounded-3xl bg-secondary-coral text-white">
                        Search
                    </button>
                </div>
            </div>
        </div>
    );
  };

export default SearchBar;