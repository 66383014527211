import Landing from "../../components/EmployerHome/landing";
import Partners from "../../components/EmployerHome/partners";
import WhyStint from "../../components/EmployerHome/whyStint";
import WhyHire from "../../components/EmployerHome/whyHire";
import Candidates from "../../components/EmployerHome/candidatePool";
import Offering from '../../components/EmployerHome/Offering/Offering';
import FAQ from '../../components/FAQs/FAQ';
import EmployerTestimonial from '../../components/Testimonial/EmployerTestimonial';

const EmployerLanding = () => {
  function learnMoreScroll(){
    const element = document.getElementById("offerings");
    if (element) {
      setTimeout(function () {
        element.scrollIntoView({
            block: "start",
        });
    }, 100);
    }
  }
  
    return (
    <div>
      <div class = "bg-primary-purple">
        {Landing(learnMoreScroll)}
      </div>
      <div class = "backgroundS">
        <div class = "py-10">
          <Partners/>
        </div>
        <div class = "px-8 md:px-24 py-5 md:pb-10 md:pt-12">
          <WhyStint/>
        </div>
        <div id = "hire" class = "px-8 md:px-24 pt-12 md:pt-24 pb-8 md:pb-16">
          <WhyHire/>
        </div>
        <div class = "px-8 md:px-24 pt-10 pb-20 md:pb-32">
          <Candidates/>
        </div>
        <div id = "offerings" class = "px-2 md:px-10 bg-primary-purple py-10">
          <Offering/>
        </div>
        <div id = "testimonials">
          <EmployerTestimonial/>
        </div>
      </div>
      <div>
          <FAQ type="employer"/>
      </div>
      <div class = "bg-primary-purple py-5">
            <p class = "pt-20 section-heading text-section-heading-mobile md:text-section-heading text-white">
                Want to learn more?
            </p>
            <p class = "px-8 py-4 paragraph-three text-white text-paragraph-three-mobile md:text-paragraph-three">
              Have questions about how we can support your hiring needs? Chat with the team! 
            </p>
            <button class = "mb-12 mt-10 bg-white px-3 py-2 rounded-full">
            <a href = "https://airtable.com/appyT1YN8YDfocPTw/shrLi8VMRhMEWRpcd">
                <p class = "button text-button-mobile md:text-button text-black text-center">
                    Get in touch
                </p>
            </a>
            </button>
        </div>
    </div>
     

    );
  };

export default EmployerLanding;