import Title from "../../components/Blogs/title";
import MohitAuthor from '../../assets/Images/MohitRauAuthor.jpeg'
import { useEffect } from "react";
const LinkedinRequest = () => {
    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/blogs" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/LinkedInRequest.jpg')} class = "object-contain w-full"/>
                    <p class = "paragraph-four text-left text-stone-400 mt-3 text-paragraph-four-mobile md:text-paragraph-four w-5/6">
                        {/* Credits: Ailene Susanto */}
                    </p>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Hiring", 
                        "7 Principles to Get Anyone to Accept Your LinkedIn Request", 
                        "Mastering LinkedIn can be a game-changer for international students. Connecting with peers and industry leaders can significantly enhance your job search and professional growth.",
                        "Mohit Rau", 
                        MohitAuthor, 
                        "16th August 2024")}
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        
                        Through reading books on communication, I've learned how to get peers and industry leaders to accept my LinkedIn requests. The key is personalizing your outreach and crafting messages that are difficult to ignore. <br></br><br></br>
                        Without personalization, you leave people to judge your profile, and most won’t take the time. Here are seven principles to help you write compelling messages and build a valuable network:<br></br><br></br><br></br>



                        <span className="font-extrabold">TL;DR</span>: To craft LinkedIn connection requests that work, follow these seven principles:<br></br>
                        <span style={{ marginLeft: "20px" }}>1. Use people’s names.<br></br></span>
                        <span style={{ marginLeft: "20px" }}>2. Find common ground.<br></br></span>
                        <span style={{ marginLeft: "20px" }}>3. Show genuine interest.<br></br></span>
                        <span style={{ marginLeft: "20px" }}>4. Be authentic.<br></br></span>
                        <span style={{ marginLeft: "20px" }}>5. Add value.<br></br></span>
                        <span style={{ marginLeft: "20px" }}>6. Build credibility.<br></br></span>
                        <span style={{ marginLeft: "20px" }}>7. Accept and move on.<br></br><br></br></span>



                        {/* Divider line */}
                        <hr style={{ borderTop: "1px solid #ccc", marginTop: "20px", marginBottom: "20px" }} /><br></br>




                        <span class = "font-extrabold">1. Use People’s Names</span><br></br>
                        Always address the person by their first name. It’s a small gesture that shows you've taken the time to know who they are.<br></br><br></br>
                        Avoid: “Hi there”<br></br>
                        Use: &quot;Hi &lt;Name&gt;&quot;<br></br>
                        Using someone's name immediately creates a sense of connection.<br></br><br></br>
                        Quote: “Remember that a person’s name is, to that person, the sweetest sound in any language.” – Dale Carnegie, How to Win Friends and Influence People<br></br><br></br><br></br>



                        <span class = "font-extrabold">2. Find Common Ground</span><br></br>
                        Connecting is much easier when you highlight something you both share. Reference a common industry, university, or a mutual connection to create a natural entry point for conversation.<br></br><br></br>
                        Industry: “As someone interested in soccer, I found your post on [soccer topic] insightful.”<br></br>
                        University: “I saw you graduated from [University] in [Year]. I was involved in [club/activity] during that time too.”<br></br><br></br>
                        Mutual Connection: “[Name] mentioned your expertise in [topic]. I’d love to hear your insights.”<br></br>
                        This avoids making your request feel random and irrelevant.<br></br><br></br>
                        Quote: “When you show people that you understand where they are coming from, it increases your ability to persuade them.” – Chris Voss, Never Split the Difference<br></br><br></br><br></br>



                        <span class = "font-extrabold">3. Show Genuine Interest</span><br></br>
                        Generic compliments won’t work. People can tell the difference between flattery and sincere interest.<br></br><br></br>
                        Highlight achievements: Did they win an award or write a thought-provoking post? Mention it.<br></br>
                        Ask specific questions: Instead of asking “How are you?”, engage with their work. For example, “I’d love to hear more about your recent [project].”<br></br><br></br>
                        Showing genuine curiosity about their experiences makes you stand out.<br></br><br></br>
                        Quote: “Effective communicators make the other person feel important, understood, and respected.” – John Maxwell, Everyone Communicates, Few Connect<br></br><br></br><br></br>



                        <span class = "font-extrabold">4. Be Authentic</span><br></br>
                        Be upfront about why you’re reaching out. Clearly explain your intentions, but do so respectfully. This demonstrates that you value their time.<br></br><br></br>
                        Example: “I’m reading 100 books this year and connecting with [migrants, students, leaders] to learn from their stories. I believe learning from others’ experiences is powerful.”<br></br><br></br>
                        If you’re connecting with someone you don’t know, tell them upfront.<br></br>
                        Example: “Hi Mohit, I know this message is a bit out of the blue, but…”<br></br><br></br>
                        Quote: “People won’t always remember what you say, but they will remember how you made them feel.” – Dale Carnegie, How to Win Friends and Influence People<br></br><br></br><br></br>



                        <span class = "font-extrabold">5. Add Value</span><br></br>
                        Don’t just reach out to take—offer something in return. You can share resources, insights, or connections even if you don’t have much to give.<br></br><br></br>
                        Example: “I read your post about job hunting and thought this [link to resource] might be useful.”<br></br><br></br>
                        Position yourself as someone who brings value, even in small ways, and fosters mutually beneficial connections.<br></br><br></br>
                        Quote: “If you want to be interesting, be interested. Ask questions that show you’ve thought deeply about the other person’s perspective.” – Leil Lowndes, How to Talk to Anyone<br></br><br></br><br></br>



                        <span class = "font-extrabold">6. Build Credibility</span><br></br>
                        To boost your chances of getting accepted, be active on LinkedIn. Post regularly—at least once a week—to demonstrate your credibility.<br></br><br></br>
                        Engage with others’ posts, ask thoughtful questions, and start discussions.<br></br><br></br>
                        By doing this, you’ll transform your outreach from a one-on-one conversation to broader engagement across the platform.<br></br><br></br>
                        Quote: “Influence is more about listening and adding value than just talking.” – Jack Schafer, The Like Switch<br></br><br></br><br></br>



                        <span class = "font-extrabold">7. Acceptance and Moving On</span><br></br>
                        Not everyone will accept your request—and that’s okay. It’s important not to take it personally. LinkedIn has over a billion users, so if one person doesn’t connect, move on to the next.<br></br><br></br>
                        You never know what’s happening in someone’s life or whether they’re even active on LinkedIn. Don’t dwell on a single rejection—focus on building a diverse network instead.<br></br><br></br>
                        Quote: “The quality of your life depends on the quality of your relationships.” – Joseph Grenny, Crucial Conversations<br></br><br></br><br></br><br></br>



                        <div>
                        <span className="font-extrabold">Recommended Reads on Communication:</span>
                        <ul style={{ listStyleType: '"\\2022"', paddingLeft: "20px" }}>
                            <li style={{ textIndent: "10px" }}>How to Win Friends and Influence People by Dale Carnegie</li>
                            <li style={{ textIndent: "10px" }}>Crucial Conversations by Joseph Grenny et al.</li>
                            <li style={{ textIndent: "10px" }}>Never Split the Difference by Chris Voss</li>
                            <li style={{ textIndent: "10px" }}>How to Talk to Anyone by Leil Lowndes</li>
                            <li style={{ textIndent: "10px" }}>Everyone Communicates, Few Connect by John Maxwell</li>
                            <li style={{ textIndent: "10px" }}>The Like Switch by Jack Schafer</li>
                            <li style={{ textIndent: "10px" }}>Nonviolent Communication by Dr. Marshall Rosenberg</li>
                        </ul>
                        </div>
                    </p>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default LinkedinRequest;