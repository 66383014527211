import Title from "../../components/Blogs/title";
import Ashley from "../../assets/Images/Ashley.jpg"
import { useEffect } from "react";
const Diversify = () => {
    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/blogs" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/Sequoia Blog.jpg')} class = "object-contain w-full h-full"/>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Events", 
                        "If there is one thing you should take away from this week, it’s diversify.", 
                        "Well sure, the speakers during the Stint and Sequoia Career Week may have been talking about investment portfolios and how newbies should approach purchasing shares and stocks, but personally this tidbit really holds a lot of potential in so many spaces.",
                        "Ashley Yeow", 
                        Ashley, 
                        "27th February 2023")}
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        But before we dive into that, let me explain what the Sequoia Career Week was. This leadership programme catered to students who were looking to gain career insights from some of the brightest leaders from some of the biggest companies in Australia; such as Charter Hall and Australian Unity. The week consisted of students participating in case studies and mock trials which culminated in a final presentation pitch of your allocated company. Alongside this, students were able to network and learn from experts in the financial industry such as the CEO of Novatti to a partner at law firm Hall & Wilcox.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Not only that, it also gave students a quick peek into working life in Melbourne. Office spaces with gorgeous views and friendly staff from each company gave us a sneak peek into working life in Melbourne. It gave us a sense of comfort that choosing to stay in Melbourne to pursue a career was a good choice, but it also made us all the more competitive to try and get a job in this vibrant city.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        To help with this, industry experts taught and advised the students on invaluable skills such as learning how interviewers vet their applicants, and how to best conduct yourselves at assessment centres. But the most important and frequent piece of guidance that all the companies brought up was that it pays to be diversified.
                    </p>
                </div>
                <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10 py-2 md:py-5">
                    <div class = "col-span-12 md:col-span-6">
                        <img src={require('./../../assets/Images/Sequoia Blog2.jpg')} class = "w-full"/>
                    </div>
                    <div class = "col-span-12 pt-5 md:pt-0 md:col-span-6 w-full h-fit">
                        <img src={require('./../../assets/Images/Sequoia Blog4.jpg')} class = "w-full"/>
                    </div>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        One of the go-to buzzwords in any industry; diversification. This can mean so many things, and from a financial analysts’ point of view, many of them talked about the diversification of investments - never putting all of your eggs into one basket, split your risks. But many of the professionals that we talked to during the week also discussed diversity in terms of your network, your experiences, and even in hiring for companies.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Trying new things if you had time was something that many professionals during that week recommended. Doing contract work, volunteering, and dipping your toes in as many industries as possible, it was touted as one of the best ways in understanding what you liked most, and what you wanted to pursue as a career. And this was exactly why so many students appreciated the Sequoia Career Week!
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        It allowed students to see so many different industries in a short time span, narrowing down the industries that excited and scared us as prospective workers. Many students that I talked to after the week ended knew what they wanted to go into compared to the very first day when I first met them. Sequoia Career Week was an experience that truly helped me and the other students that joined, helping us make friends and invaluable connections with industry experts, and is something that I highly recommend when it happens again in the future!
                    </p>
                </div>
                <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10 py-2 md:py-5">
                    <div class = "col-span-12 md:col-span-8">
                        <img src={require('./../../assets/Images/Sequoia Blog3.jpg')} class = "w-full"/>
                    </div>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default Diversify;