import TickComp from './tickComponent';

const WhyHire = () => {
    return (
        <div class = 'grid grid-cols-6 gap-y-5 md:grid-cols-12 md:gap-x-10' id = "hire">
            <div class = "col-span-6 h-full">
                <p class = "text-left sub-heading text-sub-heading-mobile md:text-sub-heading text-primary-navy">
                    Why hire international students?
                </p>
                <p class = "text-left paragraph-two text-paragraph-two-mobile md:text-paragraph-two pt-5 md:pt-12 pb-3">
                    Hiring international students in Australia offers numerous benefits to your company:
                </p>
                <div class = 'grid grid-cols-1 md:grid-cols-2 gap-y-6 gap-x-3 pt-5 md:pt-10'>
                    {TickComp("Multilingual")}
                    {TickComp("Diverse perspectives")}
                    {TickComp("Cultural insights")}
                    {TickComp("Strong work ethic")}
                    {TickComp("Global mindset")}
                    {TickComp("Hungry for work")}
                    {TickComp("Adaptability")}
                    {TickComp("Resilience")}
                    {TickComp("Loyalty")}
                    {TickComp("Courage")}
                </div>
            </div>
            <div class = "col-span-6 h-full">
                <img loading="lazy" src={require('./../../assets/Images/hireStats.png')} class = "w-full pt-3 md:pt-0 md:pr-0"/>
            </div>
        </div>    
    );
  };

export default WhyHire;