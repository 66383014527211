import { useState } from "react";

export const JobTypeHook = () => {   
    // Define state to store search state
    const [jobTypes, setJobTypes] = useState([]);

    // Create function to add
    function addJobType(jobType){
        const index = jobTypes.indexOf(jobType)
        // If it doesn't exists add it
        if (index == -1){
            setJobTypes(oldJobTypes => [...oldJobTypes, jobType])
        }
        // If it exists then remove it
        else{
            setJobTypes(oldJobTypes => oldJobTypes.filter(item => item != jobType));
        }
    }

    // Create function to clear
    function deleteJobTypes(){
        setJobTypes([])
        let checkBoxes = document.getElementsByClassName("jobTypesChecks")
        for (let i = 0; i <checkBoxes.length; i++){
            let checkBox = checkBoxes[i];
            checkBox.checked = false;
        }
    }

    return {jobTypes, addJobType, deleteJobTypes};
  }