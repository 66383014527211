import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './imageCarousel.css';

// Import image files
import image1 from "../../assets/Images/Carousel/1.png"
import image2 from "../../assets/Images/Carousel/2.png"
import image3 from "../../assets/Images/Carousel/3.png"
import image4 from "../../assets/Images/Carousel/4.png"
import image5 from "../../assets/Images/Carousel/5.png"

const ImageCarousel = () => {
  
  const images = [image1, image2, image3, image4, image5]

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    centerMode: true,
    
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
};

  return (
    <div style={{ '--dot-color': "black" }} class = "">
      <Slider {...settings} class = "">
        {images.map((image, index) => (
          <div key={index} className="flex justify-center px-6 md:px-0">
            <img src = {image} class = "w-[90%]"></img>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageCarousel;
