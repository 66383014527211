import StudentLanding from "./LandingPages/student"
import EmployerLanding from "./LandingPages/employer"
import React from 'react';
import useView from '../hooks/viewHooks';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from "react";
import { Link as Link2, animateScroll } from 'react-scroll';

const Home = () => {
    let {studentView, changeView} = useView();
    let scroll = []
    const [searchParams] = useSearchParams();
    for (const entry of searchParams.entries()) {
      scroll.push(entry[1])
    }

    useEffect(() =>{
        scrollFunction(scroll)
    })

    async function scrollFunction(query){
        if (scroll.length >= 1){
            if (studentView) {
                await changeView(); // Wait for the view change to complete
            }

            if (query != "employers"){
                const targetElement = document.getElementById(query);
                const targetPosition = targetElement.getBoundingClientRect().top;
                const offset = 0;
                const adjustedPosition = targetPosition + offset;
            
                animateScroll.scrollTo(adjustedPosition);
            }
        }
    }

    return (
    <div class = "z-0">
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "transition-all duration-700 relative">
            <div class = {`transition-all duration-700 ${studentView? "opacity-0 overflow-hidden max-h-screen absolute top-0 w-full":"w-full relative z-20 overflow-hidden"}`}>
                <EmployerLanding/>
            </div>
            <div class = {`transition-all duration-700 ${studentView? "overflow-hidden w-full z-20":"max-h-screen opacity-0 z-0 overflow-hidden absolute top-0 w-full"}`}>
                <StudentLanding/>
            </div>
        </div>        
    </div>
     

    );
  };

export default Home;