import { Link, animateScroll as scroll } from 'react-scroll';
import useView from '../../hooks/viewHooks';

const FooterLink = ({ to, ...props }) => {
    let {studentView, changeView} = useView();
    const handleOnClick = async (e) => {
      e.preventDefault();
      
      if (studentView) {
        await changeView(); // Wait for the view change to complete
      }
      const targetElement = document.getElementById(to);
      const targetPosition = targetElement.getBoundingClientRect().top;
      const offset = -100;
      const adjustedPosition = targetPosition + offset;
  
      scroll.scrollTo(adjustedPosition, props);
    };
  
    return <Link {...props} to={to} onClick={handleOnClick}>{props.children}</Link>;
};

export default function StudentEmployersOthers() {
    return(
        <div class = "grid grid-cols-12 text-left gap-y-8 md:gap-y-0">
            <div class = "col-span-12 grid grid-cols-1 gap-y-2 md:col-span-4 md:text-left text-center">
                <p class="paragraph-one-bold text-sub-heading-mobile md:text-paragraph-one text-white">Students</p>
                <div className="md:pt-4"><a href="https://docs.google.com/forms/d/e/1FAIpQLScEg-PTGW04bk_ctv62nwj0t2gj_ZBI7BVQj82L4lQtb5AI2g/viewform" class="caption text-paragraph-two-mobile md:text-caption text-white">Community</a></div>
                <div className="md:pt-4"><a href="/job-board" class="caption text-paragraph-two-mobile md:text-caption text-white">Job board</a></div>
                <div className="md:pt-4"><a href="/blogs" class="caption text-paragraph-two-mobile md:text-caption text-white">Blog</a></div>
            </div>
            <div class = "col-span-12 grid grid-cols-1 gap-y-2 md:col-span-4 md:text-left text-center">
                <p class="paragraph-one-bold text-sub-heading-mobile md:text-paragraph-one text-white">Employers</p>
                <div className="md:pt-4"><a href = "/?=offerings" className="caption text-paragraph-two-mobile md:text-caption text-white">List jobs</a></div>
                <div className="md:pt-4"><a href = "/insights" className="caption text-paragraph-two-mobile md:text-caption text-white">Insights</a></div>
                <div className="md:pt-4"><a href = "/?=testimonials" className="caption text-paragraph-two-mobile md:text-caption text-white">Testimonials</a></div>
            </div>
            <div class = "col-span-12 grid grid-cols-1 gap-y-2 md:col-span-4 md:text-left text-center">
                <p class="paragraph-one-bold text-sub-heading-mobile md:text-paragraph-one text-white">Others</p>
                <div className="md:pt-4"><a href="/about-us" class="caption text-paragraph-two-mobile md:text-caption text-white">About</a></div>
                <div className="md:pt-4"><a href="https://edoc.lawpath.com/xWeWerCbeRbWzWweXyE" class="caption text-paragraph-two-mobile md:text-caption text-white">Terms & conditions</a></div>
                <div className="md:pt-4"><a href="https://drive.google.com/file/d/1kGRl9XsN-JhNVH72jMeBMOXIQ8phKFd3/view" class="caption text-paragraph-two-mobile md:text-caption text-white">Privacy notice</a></div>
            </div>
        </div>
    );
}