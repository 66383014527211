const FounderCard = (founderImage, flagImage, name, linkedInLink, description) => {
    return (
        <div class = "bg-white h-full rounded-3xl rounded-tl-none">
            <div class = "relative">
                <img src={founderImage} class = "w-full rounded-tr-3xl"/>
                <img src={flagImage} class = "absolute w-1/6 top-0 left-0"/>
            </div>
            <div class = "grid grid-cols-12 bg-white rounded-b-3xl px-6 pt-6 pb-3">
                <div class = "col-span-10 relative">
                    <p class = "sub-heading text-sub-heading-mobile md:text-sub-heading text-black align-baseline">
                        {name}
                    </p>        
                </div>
                <div class = "col-span-2 flex items-center">
                    <a href = {linkedInLink}>
                        <img src={require('./../../assets/Images/linkedIn.png')} class = "w-full px-2"/>
                    </a>
                </div>
                <div class = "col-span-12">
                    <p class = "paragraph-four text-paragraph-four-mobile md:text-paragraph-four text-gray-500">
                        {description}
                    </p>     
                </div>
            </div>
        </div>
    );
  };

export default FounderCard;