import Title from "../../components/Blogs/title";
import NathanAuthor from "../../assets/Images/NathanAuthor.jpg"
import { useEffect } from "react";
const StudyNash = () => {
    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/blogs" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/studyNash.png')} class = "object-contain w-full h-full"/>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Community", 
                        "Stint and Studynash Join Forces to Provide Migration Support to International Students in Australia", 
                        "We are excited to announce a partnership between Stint, the community and job platform dedicated to international students and graduates in Australia, and Studynash, a new-age education and migration consulting firm.",
                        "Nathan Lee", 
                        NathanAuthor, 
                        "30th August 2024")}
                </div>
                <div class = "pt-10 col-span-12 md:hidden w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/studyNash.png')} class = "object-contain w-full h-full"/>
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    This partnership aims to provide international students with a comprehensive support system, from securing employment opportunities to navigating the complexities of visa and migration services. As part of the partnership, Stint community members will receive an exclusive discounted price for Studynash’s migration support services and, Studynash members will have access to Stint’s free community and job board. 
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    <a class = "text-blue-500 hover:text-blue-800" href = "https://www.linkedin.com/in/nathan-lee838/">Nathan Lee</a>, Co-Founder at Stint expressed his excitement for the partnership, “At Stint, our mission is to improve the employment outcomes for international students and graduates. We recognise the importance of migration support in helping international students build long-term careers in Australia, and are excited to partner with Studynash, who will be able to provide valuable expertise to our community.”
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    <a class = "text-blue-500 hover:text-blue-800" href = "https://www.linkedin.com/in/kunal-sitlani-5b3006168/">Kunal Sitlani</a>, COO and Registered Migration Agent (MARN: 2418523) at Studynash, shared, “At Studynash, we partner with organisations that not only do excellent work for international students but also have aligned values. One of the key values of Stint and Studynash is Community. Both organisations aim to add value to the lives of international students by building a community and providing essential services.”
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    Kunal also discussed Studynash’s goals of the partnership, “Stint does an excellent job at helping international students with employment support. Studynash wants to add more value to international students' lives by helping the community with any visa or migration services so that international students can have a worry-free time in Australia “
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    Similarly to Stint, Studynash was founded by former international students and is on a mission to educate and assist international students and migrants in their journey to migrate to Australia. With a commitment to trust, integrity, and community-first values, Studynash is not just a migration company but a community of migrants with similar values. 
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    If you’re interested in receiving migration support through the Stint and Studynash partnership, please complete the form <a class = "text-blue-500 hover:text-blue-800" href = "https://docs.google.com/forms/d/e/1FAIpQLScD_uJKzkyCXWqX_H4V_uoOUyfpSs_6gqvQIj1FWzpeGu9Rpg/viewform">here</a>. 
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    And if you haven’t already, make sure to join Stint’s free community for international students and graduates in Australia <a class = "text-blue-500 hover:text-blue-800" href = "https://docs.google.com/forms/d/e/1FAIpQLScEg-PTGW04bk_ctv62nwj0t2gj_ZBI7BVQj82L4lQtb5AI2g/viewform?usp=sf_link">here</a>! 
                    </p>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default StudyNash;