import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import backgroundImage from '../../../assets/Images/in_BW.png';

const TitleSection = ({ title, price, priceNote, popular }) => (
  <div>
    {popular && (
      <div className="absolute top-0 right-6 bg-secondary-yellow text-white paragraph-three text-paragraph-three-mobile md:text-paragraph-three px-5 py-1 lg:px-10 lg:py-2 rounded-b-xl">
        Most Popular
      </div>
    )}
    <h2 className="paragraph-one text-paragraph-one-mobile md:text-paragraph-one text-left text-primary-purple mt-6">{title}</h2>
    <p className="text-section-heading-mobile2 pt-4 md:text-main-heading2 md:pt-2 text-left text-primary-purple ">
      {price} <span className="paragraph-four text-paragraph-four-mobile md:text-paragraph-four text-gray-500">{priceNote}</span>
    </p>
  </div>
);

const FeaturesSection = ({ featureStart, features, note }) => (
  <div>
    <p className="paragraph-four text-paragraph-four-mobile md:text-paragraph-four text-gray-500 text-left mt-2 mb-4">{featureStart}</p>
    <ul className="mb-6 text-left">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center caption text-caption-mobile md:text-caption mb-5">
          <FontAwesomeIcon icon={faCircleCheck} className="text-primary-black mr-5" />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <p className="paragraph-four text-paragraph-four-mobile md:text-paragraph-four text-gray-500 mb-10 text-left">{note}</p>
  </div>
);

const OfferingCard = ({ title, price, priceNote, featureStart, features, note, popular }) => {
  return (
    <div className={`bg-white py-6 px-10 rounded-lg shadow-lg flex flex-col justify-between w-full relative`}
        style={{ 
          backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'left bottom',
          backgroundRepeat: 'no-repeat',
        }}>
      <TitleSection title={title} price={price} priceNote={priceNote} popular={popular} />
      <div className="border-t border-gray-200 my-4"></div>
      <FeaturesSection featureStart={featureStart} features={features} note={note} />
      <div className="mt-auto text-left">
        <a href = "https://airtable.com/appbzFREDaka6w9wq/shrqPnKyf7baIvCMs">
          <button className="bg-primary-purple text-white button text-button-mobile md:text-button mt-10 py-2 px-8 hover:bg-primary-lilac rounded-full">List Now</button>
        </a>
      </div>
    </div>
  );
};

export default OfferingCard;
