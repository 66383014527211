import BlogCards from "../components/Blogs/blogCards";
// Import constant data
import blogList from '../data/blogList';

const Blogs = () => {
    return (
    <div class = "z-0">
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = 'text-left px-8 md:px-24 py-8 md:py-16'>
            <p class = "section-heading text-section-heading-mobile md:text-section-heading text-primary-purple">Browse our blog</p>
            <p class = "pb-8 md:pb-16 paragraph-one text-paragraph-one-mobile md:text-paragraph-one ">Want to learn more about building a career and life in Australia as an international student?</p>
            {BlogCards("blogs", -1)}
        </div>        
    </div>
     

    );
  };

export default Blogs;