import Title from "../../components/Blogs/title";
import Ailene from "../../assets/Images/AileenAuthor.jpeg"
import { useEffect } from "react";
const Destress = () => {
    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/blogs" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/book_title.jpg')} class = "object-contain w-full h-full"/>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Lifestyle", 
                        "6 Ways to De-stress (Low-budget Edition)", 
                        "Feeling yourself on the verge of a burnout but don’t want to spend half your allowance to destress? Sit tight because here I’m about to show you six ways to destress while also keeping your wallet stuffed and happy.",
                        "Ailene Susanto", 
                        Ailene, 
                        "15th October 2024")}
                </div>
                <div class = "pt-10 col-span-12 md:hidden w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/book_title.jpg')} class = "object-contain w-full h-full"/>
                </div>
            </div>
            <div class = "pt-10">
                <p class = "paragraph-two text-paragraph-two-mobile text-left md:text-paragraph-two w-5/6">
                Everybody has heard of the same old concept of journaling right, but have you heard of <span class = "font-bold">‘junk journaling'</span>? Currently trending on TikTok, this play of concept includes collecting ‘junk’ from day to day activities and slapping them onto a blank journal.

                <br></br><br></br>For this journal spread, you can literally use anything you can salvage, and mix and match to your heart’s desire. Recently received a package? Don’t throw away the Thank You card. Visited a cafe with cute napkins? Slip one into your bag and bring it home. Found an aesthetic flyer? Cut them into the pieces that you like. Think of it as a creative outlet where you can detach and gather your thoughts again. Bonus points for serving as a memory keepsake and the aesthetically pleasing result in the end!

                <br></br><br></br>Maybe collecting junk is not really your thing. Then how about <span class = "font-bold">painting</span>? Drop by a Kmart on your way to a beach day or a picnic in the garden to get one of their Painting by Number sets for a really good value. There is just something about stroking the paintbrush along the predrawn lines and filling in the spaces that feels so fulfilling. Think of it as a fail-proof method for distraction and perhaps even a new addition to your room decor!

                <br></br><br></br>Next is my personal favourite because, what better way than to sing (scream) your stress out? Shine the spotlight on Kono, Melbourne’s only <span class = "font-bold">coin karaoke booth</span>! Ranging from $5 to $20 per person depending on the number of people you are going with, you will be paying for the number of songs you’d like to sing instead of a set amount of time so take that breather in between and when you're done, continue to scream out all those pent up emotions!

                <br></br><br></br>If you love books, this one's for you. Visit one of Melbourne’s many many <span class = "font-bold">libraries</span> scattered around the city and just flick through their huge collection of books. Whether you’re in the mood for something thoughtful, inspiring, romantic, thrilling or if you just want to scour around and discover titles, I promise their catalogue will not disappoint. And if you come across something you’d love to read, simply register for a library card and borrow that book right away!

                <br></br><br></br>Or do you prefer visuals over words? Easy. Do a <span class = "font-bold">movie marathon night</span> in your very own cosy home. Turn off the lights, light some candles, make yourself a warm beverage and sink into your couch or bed. To destress, I typically would choose a movie or a series of movies that dwells in fantasy or sci-fi to escape reality for a little while. If you are looking for a peaceful and pleasant production yet fulfilling plot, Ghibli movies are the go-to. Seriously, get lost in them for one night and root yourself back in reality the next morning with a clearer mind.

                <br></br><br></br>Lastly, have a <span class = "font-bold">cook- or bake-off with your friends</span>! Bring that competitive energy and combine in with a hint of fun and yummy results. What can be better than an all-in-one deal? A fun activity, check. Satisfy your hunger, check. Create a cherishable memory, check. So what are you waiting for? Go organise your very own Masterchef at home!

                <br></br><br></br>Now that you’ve reached the end, I hope at least one of these can help you de-stress, especially with exams just around the corner. Good luck, I’ll be cheering for you!
                </p>
            </div>
        </div>        
    </div>
     

    );
  };

export default Destress;