import JobBoardCard from "./jobBoardCard"

const JobBoardCards = (jobsData, isValid, cardsLength, setPopup) => {

    return (
        <div class = "grid grid-cols-12 lg:gap-x-5 gap-y-8 md:gap-y-5 px-10 pb-8">
            <div class = "text-paragraph-two-mobile md:text-paragraph-two paragraph-two-bold col-span-6 md:col-span-12 text-left relative">
                <span class = "absolute md:relative bottom-0">{cardsLength} results</span>
            </div>
            <div class = "col-span-6 md:hidden relative">
                <button onClick = {() => setPopup(true)}>
                    <img src={require('./../../assets/Images/Icons/filter.jpg')} class = "h-6 absolute right-0 top-0"/>
                </button>
            </div>
            {jobsData.map((job) => (
                isValid(job)?
                <div class = "jobCard col-span-12 lg:col-span-6 h-full">
                    {JobBoardCard(job)}
                </div>:
                <></>
            ))}
        </div>
    );
  };

export default JobBoardCards;