import React, { useState } from 'react';
import IndexNumber from './IndexNumber';
import QuestionAndAnswer from './QuestionAndAnswer';
import ToggleButton from './ToggleButton';

const Accordion = ({ num, question, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <div className="border-b border-gray-300">
      <div
        className="flex items-center py-4 cursor-pointer"
        onClick={() => setAccordionOpen(!accordionOpen)}
      >
        <IndexNumber num={num} />
        <QuestionAndAnswer
          question={question}
          accordionOpen={accordionOpen}
        />
        <ToggleButton accordionOpen={accordionOpen} />
      </div>
      <div
        className={`pl-custom-8 md:pl-custom-16 lg:pl-custom-28 mt-2 transition-all duration-200 ease-in-out overflow-hidden ${
          accordionOpen ? 'max-h-screen opacity-100 pb-4' : 'max-h-0 opacity-0'
        }`}
      >
        {question == "What is the process for adding job posts to Stint?"?
        <>
          {accordionOpen && (
            <div className='text-left'>
              It’s simple! Just fill in the details of your open jobs on this form  <a class = "text-blue-500 hover:text-blue-800 underline" href = "https://airtable.com/appbzFREDaka6w9wq/shrqPnKyf7baIvCMs">here</a> and we’ll make sure your job gets in front of diverse, multilingual candidates.
            </div>
          )}
        </>:
        <>
          {question == "How can I improve my chances of finding a job through Stint?"? 
            <div className='text-left'>
              Join our community <a class = "text-blue-500 hover:text-blue-800 underline" href = "https://docs.google.com/forms/d/e/1FAIpQLScEg-PTGW04bk_ctv62nwj0t2gj_ZBI7BVQj82L4lQtb5AI2g/viewform">here</a>! You’ll make connections with other international students from across Australia and gain access to free resources such as an employability course, resume reviews and events.
            </div>:
            <>
            {accordionOpen && (
              <div className='text-left'>{answer}</div>
            )}
            </>}
        </>}
      </div>
    </div>
  );
};

export default Accordion;
