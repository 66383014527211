import Title from "../../components/Blogs/title";
import AileenAuthor from '../../assets/Images/AileenAuthor.jpeg'
import { useEffect } from "react";

// Remove this 
import BlogSection from "../../components/Blogs/blogSection";

const StudySlump = () => {
    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    const testSectionTitle = "Motivate yourself"
    const testSectionText = "Everything has a cause, even your study slump and as the famous saying goes, the only way to solve it is to ‘cut it off from its roots’. This is where you think about what your go-to method of self-expression is. You cannot possibly think clearly if your mind is as blurry as Melbourne’s worst hailstorm, so let’s start by straightening every entangled thought in your mind.\n\nStart easy by going out and connecting with nature. Sit at the park, get some vitamin D, watch the sunrise. If you feel like it, get in tune with your body with low impact exercises like pilates or yoga and be surprised with how refreshed you will be by the end of the session! \n\nPlan your day or fill in your to-do list. Planning your activities can give you that subtle sense of control that is impactful when you feel like everything else is going crazy, but give yourself space and don’t pack in every minute of your schedule as it might backfire and leave you disappointed. Some good tools I use or have seen a friend use are Structured (day planner) and Notion (to-do lists, calendars, activity trackers etc). \n\nLastly, this might not work for everyone but personally, I get influenced a lot by other people so watching productive vlogs as entertainment while eating my breakfast really helps me get in the groove for the study session I have planned later in the day. As they say, romanticise your life! \n\n"
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/blogs" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/StudySlump.jpg')} class = "object-contain w-full"/>
                    <p class = "paragraph-four text-left text-stone-400 mt-3 text-paragraph-four-mobile md:text-paragraph-four w-5/6">
                        Credits: Ailene Susanto
                    </p>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Lifestyle", 
                        "How to Get Out of Your Study Slump", 
                        "Does anyone else feel as if they blinked and it’s week seven of uni already? And unless you are a part of that tiny tiny tiny percentage of superhuman students who are somehow still bulldozing through the weeks, I’d say you’re close to or are already stuck in a study slump. But that’s totally fine because guess what, me too! If I can get myself out, you can too, so come close, let me let you in on what helped me.",
                        "Ailene Susanto", 
                        AileenAuthor, 
                        "13th August 2024")}
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        <span class = "font-extrabold">Identify the cause</span>
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Everything has a cause, even your study slump and as the famous saying goes, the only way to solve it is to ‘cut it off from its roots’. This is where you think about what your go-to method of self-expression is. You cannot possibly think clearly if your mind is as blurry as Melbourne’s worst hailstorm, so let’s start by straightening every entangled thought in your mind. <br></br><br></br>
                        My go-to method for this is journaling. Keeping a journal doesn’t mean you need to write something every day, it just serves as an amazing place to vent. Imagine a place where you can just let everything out, talk about that thing or person that has been bothering you without judgement. Doesn’t that just feel so freeing? <br></br><br></br>
                        If you’re not a writing type of person, try talking it out with a friend or someone you trust (you don’t want those details to get out, don’t you?). Lay everything out to them because believe it or not, you yourself will also be listening. Maybe you’ll get to see it with a new perspective and understand yourself better too. <br></br><br></br>
                        Figure out what is causing your demotivation, free yourself of the heavy thoughts clouding your mind and we can start from there! <br></br><br></br>
                    </p>
                </div>
                <BlogSection
                sectionTitle = {testSectionTitle}
                sectionText = {testSectionText}
                />
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        <br></br><span class = "font-extrabold">Execution</span><br></br><br></br>
                        So we’re all motivated and ready to go. Wait, go where? Anywhere you feel most comfortable studying in but not too comfortable like your bed, of course. Think about every study session you’ve had, which one has been the most productive and most importantly, where? It can be a cafe, a library, or one of the buildings on your campus. <br></br><br></br>
                        Personally, you’ll never catch me studying on campus. An overpriced matcha will be my study partner for life but if libraries are more your thing, maybe check out our recent blog, <a class = "text-blue-500 hover:text-blue-800" href = "https://www.stintcommunity.com/blog-libraries">Best Libraries to Study At For International Students in Melbourne</a> for some options! I find going to a place where you can see people and where people can see you can push you to do your work but if you think you’d be too easily distracted, perhaps opt for a quieter and peaceful space where now is probably a good time to explore your campus for that spot.<br></br><br></br>
                        Find your study playlist and tune out the rest of the world. It’s just going to be you and your assignment (or whatever task you have planned), bound together till that due date. Rotate through different playlists so you don’t get bored and a great tip I picked up from a friend: when writing, choose a song in a language you don’t understand so your train of thought won’t get dragged away by the lyrics. <br></br><br></br>
                        Finally, don’t be too hard on yourself. Understand there will be good productive days where you tick off all your to-do lists, catch up with your latest lectures and submit that assignment, but there will also be bad exhausting days where your brain just wants to shut down and you desperately can’t take any more work. Both are okay and totally normal. <br></br><br></br><br></br>
                        Take care of yourself, enjoy a movie or eat a good meal at the end of the day and if you find yourself falling into a study slump, reread this blog and get back on your feet! Remember, you are your biggest cheerleader. Good luck!
                    </p>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default StudySlump;