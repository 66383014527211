import React, { useState } from 'react'
import { Link } from "react-router-dom";
import NavbarSwitch from './NavbarSwitch';
import NavbarSwitchV2 from './NavbarSwitchV2';
import { RxHamburgerMenu, RxCross2 } from "react-icons/rx";
import whiteStintLogo from '../../assets/Images/Stint_Logo_White_RGB_cropped2.png'
import { Link as Link2, animateScroll as scroll } from 'react-scroll';
import useView from '../../hooks/viewHooks';
import NavbarLink from '../../pages/home'

const EmployerNavbar = () => {

  const [openMenu, setOpenMenu] = useState(true)
  function toggleHamburger() {
    setOpenMenu(!openMenu)
  }
  return (
    <>
      <nav className='sticky z-50 top-0 flex items-center bg-secondary-steel px-5 md:px-10 flex-1'>
        <ul className='flex items-center w-full h-full'>
          <li className='flex justify-around items-center pr-4 md:w-1/3 w-full h-full'>
            <Link to="/" className=' md:pr-0 sm:pr-24 flex items-center'>
              <img loading="lazy" src={whiteStintLogo} className='object-fill py-5 mb-2' />
            </Link>
            <NavbarSwitch />
          </li>
          <li className=' md:flex w-1/3 justify-around hidden pl-10'>
            <Link to="/?=offerings" className='paragraph-three text-white'>List jobs</Link>
            <Link to="/insights" className='paragraph-three text-white'>Insights</Link>
            <Link to="/about-us" className='paragraph-three text-white'>About</Link>
          </li>
          <li className='md:block hidden ml-auto h-auto w-auto py-1.5 px-3 md:mr-10 rounded-full'>
            <a href="https://airtable.com/appyT1YN8YDfocPTw/shrLi8VMRhMEWRpcd" className='py-3 px-5 rounded-full  bg-white paragraph-three text-black font-extrabold'>
              Contact us
            </a>
          </li>
        </ul>
        <div className='md:hidden mr-2 sm:mr-5'><button onClick={toggleHamburger}>{openMenu ? <RxHamburgerMenu /> : <RxCross2 />}</button></div>
      </nav>
      <div className='bg-secondary-steel relative z-50'>
        {openMenu ? (
          null
        ) : (
          <div className='h-screen w-screen bg-secondary-steel sticky top-0 z-50 fixed-position-on-mobile'>
            <ul className='flex flex-col pt-5 gap-y-5'>
              <li ><Link onClick={toggleHamburger} to="/?=offerings" className='paragraph-three text-paragraph-three-mobile text-white'>List jobs</Link></li>
              <li><Link onClick={toggleHamburger} to="/insights" className='paragraph-three text-paragraph-three-mobile text-white'>Insights</Link></li>
              <li ><Link onClick={toggleHamburger} to="/about-us" className='paragraph-three text-paragraph-three-mobile text-white'>About</Link></li>
              <li onClick={toggleHamburger} className=''>
                <a href="https://airtable.com/appyT1YN8YDfocPTw/shrLi8VMRhMEWRpcd" className='paragraph-three text-paragraph-three-mobile font-extrabold text-white'>
                  Contact us
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </>
  )
}
export default EmployerNavbar;