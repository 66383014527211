import { useState } from "react";

export const PrReqsHook = () => {   
    // Define state to store search state
    const [prReqs, setPrReqs] = useState([]);

    // Create function to add
    function addPrReq(prReq){
        const index = prReqs.indexOf(prReq)
        // If it doesn't exists add it
        if (index == -1){
            setPrReqs(oldPrReqs => [...oldPrReqs, prReq])
        }
        // If it exists then remove it
        else{
            setPrReqs(oldPrReqs => oldPrReqs.filter(item => item != prReq));
        }
    }

    // Create function to clear
    function deletePrReqs(){
        setPrReqs([])
        let checkBoxes = document.getElementsByClassName("prReqsChecks")
        for (let i = 0; i <checkBoxes.length; i++){
            let checkBox = checkBoxes[i];
            checkBox.checked = false;
        }
    }

    return {prReqs, addPrReq, deletePrReqs};
  }