import React from 'react';
import BlogCard from './blogCard';
// Import constant data
import blogList from '../../data/blogList';
import insightList from '../../data/insightList';

const BlogCards = (mode, end) =>{
    return(
    <div className='grid md:grid-cols-3 grid-cols-1 md:gap-x-10 md:gap-y-10 gap-y-10'>
        {mode == "blogs"?
        <>
        {blogList.slice(0,end).map((item) => (
            <div key={item.blogID}>
                <BlogCard
                blogImg={item.blogImg}
                blogTag={item.blogTag}
                blogTitle={item.blogTitle}
                blogDescription={item.blogDescription}
                blogAuthorImg={item.blogAuthorImg}
                blogAuthor={item.blogAuthor}
                blogPublishDate={item.blogPublishDate}
                blogLink={item.blogLink}
                />
            </div>
        ))}
        </>:
        <>
        {insightList.map((item) => (
            <div key={item.blogID}>
                <BlogCard
                blogImg={item.blogImg}
                blogTag={item.blogTag}
                blogTitle={item.blogTitle}
                blogDescription={item.blogDescription}
                blogAuthorImg={item.blogAuthorImg}
                blogAuthor={item.blogAuthor}
                blogPublishDate={item.blogPublishDate}
                blogLink={item.blogLink}
                />
        </div>
        ))}</>}
    </div>
    )
}
export default BlogCards;