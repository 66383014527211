const BlogSection = ({ sectionTitle, sectionText}) =>{
    return (
        <div>
            <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                <br></br><span class = "font-extrabold">{sectionTitle}</span><br></br><br></br>
                {sectionText.split('\n').map((line, index) => (
                    <span key={index}>
                        {line}
                        {index < sectionText.split('\n').length - 1 && <br />}
                    </span>
                ))}
            </p>
        </div>
    )
};
export default BlogSection;