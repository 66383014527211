import React from 'react';
import TestimonialEmployers from './TestimonialEmployers';
import Prachi from './../../assets/Images/Prachi.png'
import Olivia from './../../assets/Images/Olivia.png'
import Riyadh from './../../assets/Images/Riyadh.png'
import Kay from './../../assets/Images/Kay.png'
const EmployerTestimonial = () => {
  const testimonials = [
    {
      text: "Stint is a platform dedicated to the support of international students and with many of the team being former international students they are aware of the challenges faced by international students and also the great benefits they can bring to an organisation.",
      author: "Olivia Doyle",
      position: "Swinburne University",
      image: Olivia
    },
    {
      text: "I am very impressed with the level of swift communication and the candidate's readiness for work and interviews. They are all very good quality candidates who come from a wide range of cultures and ethnic backgrounds.",
      author: "Jamie Mi",
      position: "Kay & Burton",
      image: Kay
    },
    {
      text: "As part of the international education community here in Melbourne, I have had the pleasure of attending several events organized by the Stint team. Nathan and Enya's efforts to motivate, educate, and inspire are truly commendable.",
      author: "Prachi Tyagi",
      position: "IDP Education",
      image: Prachi
    },
    {
      text: "Stint and The Couch have been instrumental in transforming the employability landscape for international students through their collaborative efforts in organising a series of successful events. The partnership has fostered a sense of community and support.",
      author: "Riyadh Almalki",
      position: "The Couch",
      image: Riyadh
    }
  ];

  return (
    <TestimonialEmployers
      backgroundColor="bg-secondary-steel"
      title="Stint helps employers hire diverse candidates"
      description="Hear how Stint helps connect companies with international students and graduates from Australia's top universities."
      textColor="text-primary-white"
      testimonials={testimonials}
    />
  );
};

export default EmployerTestimonial;
