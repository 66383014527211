import { useState } from "react";

export const SearchHook = () => {   
    // Define state to store search state
    const [search, setSearch] = useState("");
    
    function deleteSearch(){
      let searchEle = document.getElementById("search");
      searchEle.value = "";
      setSearch("")
  }
    return {search, setSearch, deleteSearch};
  }