import React  from "react";
import StudentNavbar from "./StudentNavbar";
import EmployerNavbar from "./EmployerNavbar";
import useView from "../../hooks/viewHooks";

const Navbar = () => {
    let {studentView, changeView} = useView();
    return(
        <div class = "sticky top-0 z-50 transition-all duration-1500">
            <div class = {`transition-all duration-1000 ${studentView? "sticky top-0 z-50":"hidden"}`}>
                <StudentNavbar/>
            </div>
            <div class = {`transition-all duration-1000 ${studentView? "hidden":"sticky top-0 z-50"}`}>
                <EmployerNavbar/>
            </div>
        </div>
    );
}
export default Navbar;