import Title from "../../components/Blogs/title";
import Ashley from "../../assets/Images/Ashley.jpg"
import { useEffect } from "react";
const Libraries = () => {
    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/blogs" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/StateLib.png')} class = "object-contain w-full"/>
                    <p class = "paragraph-four text-left text-stone-400 mt-3 text-paragraph-four-mobile md:text-paragraph-four w-5/6">
                        Credit: State Library Victoria https://www.slv.vic.gov.au/ 
                    </p>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Community", 
                        "Best Libraries to Study At For International Students in Melbourne", 
                        "If you’re like me, and you find the siren call of your bed or your couch too alluring to study properly, you’re probably on the lookout for a nice, quiet space to hunker down. Well don’t worry, I’ve got your back.",
                        "Ashley Yeow", 
                        Ashley, 
                        "25th August 2024")}
                </div>
                <div class = "pt-10 col-span-12 md:hidden w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/StateLib.png')} class = "w-full"/>
                    <p class = "paragraph-four text-left mt-3 text-stone-400 text-paragraph-four-mobile md:text-paragraph-four w-5/6">
                        Credit: State Library Victoria https://www.slv.vic.gov.au/ 
                    </p>
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Here are some of the absolute best libraries to study at in Melbourne.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        First up, we have <span class = "font-extrabold">‘Library by the Dock’</span>. Now this one is definitely a classic for those who like having a nice view to look at while on your study break. Paired with floor to ceiling glass windows that overlook the waterfront, and recreational spaces like a ping-pong table and music rooms, this library has everything that you might possibly need. The space is large and quiet, and if you’re suddenly peckish for a snack, there are a lot of really nice cafes and restaurants around too.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        But if you find that ‘Library at the Dock’ is a little too far for your taste, you can also try the newly opened <span class = "font-extrabold">narrm ngarrgu </span>library. Translated into the Wurundjeri Woi-wurrung language meaning ‘Melbourne Knowledge’, the narrm ngarrgu library was built in collaboration with First Nations people, and is the first space of its kind. With contemporary Aboriginal artwork weaved through the design of the building, narrm ngarrgu finds its home near Queen Victoria Market. The space is made up of multiple floors and is a quiet getaway from the city’s hustle and bustle, perfect for the mind to get some study in.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Of course, no list is complete without talking about the <span class = "font-extrabold">State Library</span>. It is the go-to library for anyone in the Melbourne CBD, and rightly so. With stunning architecture and a variety of spaces for people to study and work at, State Library is one that you can always hedge your bets on and come out winning. Located right in the heart of Melbourne, not only does this mean it’s easy to get to, but it also means that when you’re done studying, it’s time for partying.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        To round off the list, my personal favourite space to study at - even though it’s not a library - is the second floor of <span class = "font-extrabold">Old Arts</span> in the University of Melbourne, Parkville Campus. If you can find it, it is a room that is secluded and antiquey, filled with mismatching chairs and built with dark hardwood panels. The lights are never on, but the large windows that overlook the trees on Professor’s Walk bring in enough brightness that you don’t even need it. Not only that, the view of the trees and students walking around, gives the room an even more magical feeling where it makes you feel like you’re not even in Melbourne. 
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        So if you’re still in a study slump, head out to one of the libraries listed above to refresh and reinvigorate your mind with a change of location. From views that look over the waterfront to architecture that was made in partnership with the Indigenous community, Melbourne has a wide array of offerings in terms of libraries, so why not put your thinking hat on and start studying? 
                    </p>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default Libraries;