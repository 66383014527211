import Title from "../../components/Blogs/title";
import Stephanie from "../../assets/Images/stephanie.jpg"
import { useEffect } from "react";
const Volunteering = () => {
    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/blogs" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/volunteering.jpg')} class = "object-contain w-full h-full"/>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Community", 
                        "Unlocking the Power of Volunteering: A Must for International Students in Melbourne", 
                        "Studying abroad is an exciting journey, and Melbourne’s vibrant culture and welcoming community make it an ideal destination for students from around the world. Want to make the most of your time here, supercharge your skills, and boost your career prospects? Dive into volunteering! Here’s why every international student in Melbourne should embrace this amazing opportunity.",
                        "Stephanie Balint - Campus Consultancy", 
                        Stephanie, 
                        "7th August 2024")}
                </div>
                <div class = "pt-10 col-span-12 md:hidden w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/volunteering.jpg')} class = "object-contain w-full h-full"/>
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        <span class = "font-extrabold">1. Connect and Thrive:</span> Volunteering isn’t just about giving back; it’s a fantastic way to dive into Melbourne’s community and grow your network. By getting involved with local charities, community groups, or university initiatives, you’ll meet people who share your passions and values. These connections can lead to lifelong friendships and valuable professional relationships that enrich your daily life and future career.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        <span class = "font-extrabold">2. Build Essential Skills:</span> Volunteering offers a wealth of skills you can use in any field. From honing your leadership and teamwork to sharpening your problem-solving and communication, you’ll gain hands-on experience that’s highly transferable. These skills will not only boost your employability but also make you stand out in the competitive job market. 
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        <span class = "font-extrabold">3. Get Real-World Experience:</span> Securing part-time jobs or internships is easier with local work experience. Volunteering immerses you in Melbourne’s work culture, showcasing your dedication to community service and adaptability. <a href = "https://www.seek.com.au/career-advice/article/3-reasons-volunteering-can-put-you-ahead-of-the-pack" class = "text-blue-500 hover:text-blue-800">SEEK research </a>found that 92% of employers say relevant volunteer experience gives candidates an advantage in job interviews. This experience can make your resume shine and give you a competitive edge in your future career pursuits. 
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        <span class = "font-extrabold">4. Embrace Leadership Opportunities:</span> Many volunteer roles offer a chance to step up as a leader. Whether you’re leading a team, organizing an event, or managing projects, these experiences will help you develop crucial leadership skills. Demonstrating your ability to lead and inspire others is a powerful asset for any career path.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        <span class = "font-extrabold">5. Dive into Melbourne's Culture:</span> Volunteering opens the door to Melbourne’s rich cultural scene. Get involved in local events, festivals, and community activities to fully experience the city’s traditions and lifestyle. This cultural immersion will broaden your perspectives and make your time in Melbourne even more rewarding. Australia has over 600,000 not-for-profit organizations, offering endless opportunities to engage with diverse cultural experiences.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        Volunteering in Melbourne is more than just an opportunity—it’s a game-changer! It’s your chance to connect with incredible people, master essential skills, gain hands-on experience, and emerge as a leader. Embrace volunteering to supercharge your personal and professional growth while making a real difference in the community. Study Melbourne offers a range of opportunities specifically designed to help international students thrive. Check out their free events, programs, and networking opportunities to see how they can help you have the best possible time in Melbourne!
                    </p>
                </div>
                <div>
                    <img src={require('./../../assets/Images/volunteering_banner.png')} class = "py-5 md:py-10 object-contain w-full h-full"/>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        <span class = "font-extrabold">Ready to jump in?</span> We are hosting two exciting FREE events in September with Study Melbourne to help you increase your volunteer and leadership skills! Each day will be packed with inspiring local guest speakers, interactive workshops, and networking opportunities. Don’t wait — <span class = "font-extrabold">register now </span>via the links below and elevate your leadership skills!
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6">
                        <span class = "font-extrabold">September 21st:</span> <a href = "https://events.humanitix.com/v2l_geelong" class = "text-blue-500 hover:text-blue-800">Study Melbourne Leadership Labs: Volunteer to Lead Geelong</a>
                    </p>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two md:w-5/6 pt-5 md:pt-0">
                        <span class = "font-extrabold">September 22nd:</span> <a href = "https://events.humanitix.com/v2lmelbourne" class = "text-blue-500 hover:text-blue-800">Study Melbourne Leadership Labs: Volunteer to Lead Melbourne</a>
                    </p>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default Volunteering;