const Landing = (learnMoreScroll) => {
    return (
        <div>
            <div class = 'grid grid-cols-6 md:grid-cols-12 md:gap-x-5 gap-y-0'>
                <div class = "relative col-span-6 bg-primary-purple mt-5 pt-12 mb-20 md:mb-20">
                    <div class = "pl-10 md:pl-16 md: pr-20 lg:pl-20 pr-10 lg:pr-24">
                        <p class = "text-main-heading-mobile md:text-main-heading main-heading text-white text-left">
                            Find international <br/>talent with global <br/> experience and <br/>skills
                        </p>
                        <p class = "text-paragraph-two-mobile md:text-paragraph-two paragraph-two text-white text-left pt-5 md:pt-5">
                            Tap into a culturally diverse pool of international students from Australia's leading universities.
                        </p>
                        <div class = 'flex items-start'>
                            <button onClick = {learnMoreScroll} class = "mt-12 rounded-full text-button-mobile md:text-button button w-fit px-5 py-3 bg-white text-black">
                                <a>
                                    Learn More
                                </a>
                            </button>
                        </div>
                    </div>
                    <img loading="lazy" src={require('./../../assets/Images/Stint_Assets_Icons-08.png')} class = "hidden md:block z-30 absolute right-0 translate-x-2/3 translate-y-1/4 mb-2 bottom-0 h-24 sm:h-32 md:h-40"/>
                </div>
                <div class = "relative col-span-6 h-full backgroundEmployer z-10"></div>
                <div class = "relative md:hidden col-span-6">
                    <img loading="lazy" src={require('./../../assets/Images/Stint_Assets_Icons-08.png')} class = "z-30 absolute top-0 -translate-y-1/2 right-10  h-24 sm:h-32 md:h-40"/>
                    <img loading="lazy" src={require('./../../assets/Images/EmloyerHome.png')} class = "w-full"/>
                </div>
            </div>
        </div>    
    );
  };

export default Landing;