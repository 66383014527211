import {Link} from "react-router-dom";
import data from "./../../data/blogTagColour.json";

const BlogCard = ({ blogImg, blogTag, blogTitle, blogDescription, blogAuthorImg, blogAuthor, blogPublishDate, blogLink}) =>{
    const colour = data[blogTag];

    return (
        <Link to={`${blogLink}`} className="text-left grid md:grid-rows-[2fr_1fr_0.5fr_1fr] h-full w-full md:gap-y-2 rounded-2xl bg-custom-gray shadow-xl">
            <div className="bg-cover bg-top relative rounded-t-2xl md:h-full h-48" 
                style={{ backgroundImage: `url(${blogImg})`}}>
                <div className={`absolute rounded-2xl bottom-2 right-2 bg-${colour} text-paragraph-five-bold md:text-paragraph-five-bold px-4 py-1 paragraph-five-bold`}>
                    {blogTag}
                </div>    
            </div>
            <p className="paragraph-one-bold text-paragraph-one-mobile md:text-paragraph-one font-bold px-6 pt-4">{blogTitle}</p>
            <p className="px-6 text-stone-500 caption text-caption-mobile md:text-caption h-fit md:pt-0 ellipsis-multiline">{blogDescription}</p>
            <div className="grid grid-cols-[1fr_4fr] pt-6 pb-4">
                <img src={blogAuthorImg} className="w-16 ml-8 self-center rounded-full justify-self-center"></img>
                <div className="grid grid-rows-2 px-6 pt-4 md:pt-0 pb-2 md:pb-0">
                    <p className="text-paragraph-two-mobile md:text-paragraph-two self-end paragraph-two-bold">{blogAuthor}</p>
                    <p className="text-paragraph-three-mobile md:text-paragraph-three text-gray-500 paragraph-three">{blogPublishDate}</p>
                </div>
            </div>
        </Link>
    );
};

export default BlogCard;