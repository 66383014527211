const Landing = (ourStoryScroll) => {
    return (
        <div>
            <div class = "grid grid-cols-12 mb-20">
                <div class = "text-left col-span-12 md:col-span-6 bg-secondary-blue h-full grid place-items-center py-10 md:pb-32">
                    <div class = "pl-20 pr-20">
                        <p class = "text-primary-purple main-heading text-main-heading-mobile md:text-main-heading">
                            Made for, and by, <br/>international <br/>students 
                        </p>
                        <p class = "text-primary-purple main-heading text-main-heading-mobile md:text-main-heading">

                        </p>
                        <p class = "text-primary-purple paragraph-two pt-5 text-paragraph-two-mobile md:text-paragraph-two md:pt-12">
                            At Stint, we are building a job platform dedicated to improving international student employment outcomes in Australia.
                        </p>
                        <button onClick = {ourStoryScroll} class = "mt-12 rounded-full text-button-mobile md:text-button button w-fit px-5 py-3 bg-white text-black">
                            <a>
                                Learn More
                            </a>
                        </button>
                    </div>
                </div>
                <div class = "md:hidden col-span-12 h-full relative">
                    <img src={require('./../../assets/Images/team.png')} class = "w-full"/>
                    <img class = "h-20 absolute translate-y-1/2 left-1/4 -translate-x-1/2 bottom-0" alt = "Icon Error" src={require('./../../assets/Images/Stint_Assets_Icons-07.png')}/>
                </div>
                <div class = "hidden md:block col-span-6 h-full relative">
                    <img src={require('./../../assets/Images/team.png')} class = "w-full"/>
                    <img class = "h-48 absolute translate-y-1/2 left-1/2 -translate-x-1/2 bottom-0" alt = "Icon Error" src={require('./../../assets/Images/Stint_Assets_Icons-07.png')}/>
                </div>
            </div>
        </div>
    );
  };

export default Landing;