import useFooter from '../hook/useFooter';
import LogoAndSocials from '../components/Footer/logoAndSocials';
import Newsletter from '../components/Footer/newsletter';
import StudentEmployersOthers from '../components/Footer/studentEmployersOthers';

export default function Footer() {
    

    return(
        <div class = "bg-primary-navy py-12 px-10 md:px-20 overflow-hidden">
            <div class = "grid grid-cols-12 lg:gap-x-20 xl:gap-x-28">
                <div class = "col-span-12 md:col-span-3">
                    <LogoAndSocials></LogoAndSocials>
                </div>
                <div class = "col-span-12 md:col-span-9 md:px-10">
                    <div class = "grid grid-cols-12"> 
                        <div class = "col-span-12 md:grid hidden">
                            <Newsletter></Newsletter>
                        </div>
                        <div class = "col-span-12 pt-10">
                            <StudentEmployersOthers></StudentEmployersOthers>
                        </div>
                    </div>
                </div>
                <div class = "col-span-12 pt-12 md:col-span-3">
                    <p class = "caption text-caption-mobile md:text-caption text-center text-white text-opacity-50 md:text-left">
                        Stint Pty Ltd, Australia
                    </p>
                </div>
            </div>
            
        </div>
    );
}