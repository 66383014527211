import { useState, useEffect } from "react";

export const SearchButtonHook = () => {   

    // Define state to store search state
    const [lockedFilters, setFilters] = useState({
        search: "",
        industries: [],
        jobTypes: [],
        prReqs: [],
        locations: [],
    });
    const [cardsLength, setCardsLength] = useState(0)

    useEffect(() => {
        setCardsLength(document.getElementsByClassName("jobCard").length)
    });

    function searchPress(filters){
        setFilters(filters)
    }

    function isValidSelection (record, selected){
        let valid = false
        for (let i = 0; i < record.length; i++){
          for (let j =0; j < selected.length; j++){
            if (record[i] === selected[j]){
              valid = true
            }
          }
        }
        return valid
      }

    function isValid(job){
        // Loop filters and check if job matches requirements
        let searchValid = lockedFilters.search.length == 0 || (
            (typeof(job.fields["Company Name"]) === 'string'? job.fields["Company Name"].toLocaleLowerCase().includes(lockedFilters.search.toLocaleLowerCase()) && lockedFilters.search.length !== 0: false) ||
            (typeof(job.fields["About"]) === 'string'? job.fields["About"].toLocaleLowerCase().includes(lockedFilters.search.toLocaleLowerCase()) && lockedFilters.search.length !== 0: false) ||
            (typeof(job.fields["Job Role"]) === 'string'? job.fields["Job Role"].toLocaleLowerCase().includes(lockedFilters.search.toLocaleLowerCase()) && lockedFilters.search.length !== 0: false)
        )
        let industryValid = (
            lockedFilters.industries.length === 0 ||
            (typeof(job.fields["Industry"]) === 'object'? isValidSelection(job.fields["Industry"], lockedFilters.industries) : false)
        ) 
        let jobTypeValid = (
            lockedFilters.jobTypes.length === 0 ||
            (typeof(job.fields["Job Type"]) === 'object'? isValidSelection(job.fields["Job Type"], lockedFilters.jobTypes) : false)
        ) 
        let prReqValid = (
            lockedFilters.prReqs.length === 0 ||
            (typeof(job.fields["PR Requirement"]) === 'object'? isValidSelection(job.fields["PR Requirement"], lockedFilters.prReqs) : false)
        ) 
        let locationValid = (
            lockedFilters.locations.length === 0 ||
            (typeof(job.fields["Location"]) === 'object'? isValidSelection(job.fields["Location"], lockedFilters.locations) : false)
        ) 
        return searchValid && industryValid && jobTypeValid && prReqValid && locationValid;
    }

    return {isValid, searchPress, cardsLength};
  }