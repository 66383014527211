import data from "./../../data/blogTagColour.json";

const Title = (tag, title, description, author, authorPic, date) => {
    const colour = data[tag];

    return (
        <div class = "text-left">
            <div class = {`w-fit bg-${colour} px-2 py-1 rounded-lg`}>
                <p class = "button text-button-mobile md:text-button">
                    {tag}
                </p>
            </div>
            <p class = "sub-heading py-2 text-sub-heading-mobile md:text-sub-heading md:py-5">
                {title}
            </p>
            <p class = "caption pb-2 text-caption-mobile md:text-caption md:pb-8">
                {description}
            </p>
            <div class = "grid grid-cols-12">
                <div class = 'col-span-1 grid place-items-center'>
                    <img class = "w-full rounded-full" alt = "Image Error" src = {authorPic}/>
                </div>
                <div class = 'col-span-11 pl-5'>
                    <p class = "paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two">
                        {author}
                    </p>
                    <p class = "caption text-caption-mobile md:text-caption">
                        {date}
                    </p>
                </div>

            </div>
        </div>
    );
  };

export default Title;