import React from 'react';
import Slider from 'react-slick';
import TestimonialCard from './TestimonialCard';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './testimonial.css';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ 
        ...style, 
        display: "block", 
        right: "25px", 
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", 
        borderRadius: "50%",
        backgroundColor: "rgba(200, 200, 200, 0.5)",
      }}
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ 
        ...style, 
        display: "block", 
        left: "25px", 
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)", 
        borderRadius: "50%",
        backgroundColor: "rgba(200, 200, 200, 0.5)",
        zIndex: 1 
      }}
      onClick={onClick}
    />
  );
}

const Testimonial = ({ backgroundColor, textColor, title, description, testimonials }) => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    centerMode: true,
    
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
};

  return (
    <div className={`pt-16 pb-28 ${backgroundColor}`} style={{ '--dot-color': "white" }}>
      <div className="max-w-screen mx-auto text-center px-0">
      <h2 className={`section-heading text-section-heading-mobile md:text-section-heading font-bold text-center mb-6 ${textColor}`}>{title}</h2>
      <p className={`paragraph-two text-paragraph-two-mobile md:text-paragraph-two mb-10 md:mb-20 ${textColor}`}>{description}</p>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="flex justify-center slide-container">
              <TestimonialCard
                title={testimonial.title}
                text={testimonial.text}
                author={testimonial.author}
                position={testimonial.position}
                image={testimonial.image}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonial;
