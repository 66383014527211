import React, {useState} from 'react';
import {Link} from "react-router-dom";
import NavbarSwitch from './NavbarSwitch';
import NavbarSwitchV2 from './NavbarSwitchV2';
import { RxHamburgerMenu, RxCross2 } from "react-icons/rx";
import purpleStintLogo from '../../assets/Images/Stint_Logo_Purple_RGB_cropped.png'

const StudentNavbar =()=> {
  const [openMenu, setOpenMenu] = useState(true)
  function toggleHamburger(){
    setOpenMenu(!openMenu)
  }

  return (
    <>
    <nav className='z-50 flex items-center bg-secondary-blue py-0 px-5 lg:px-10 flex-1 sticky top-0'>
      <ul className='flex items-center w-full h-full '>
        <li className='flex justify-around items-center pr-4 md:w-1/3 w-full h-full'>
          <Link to="/"  className=' md:pr-0 sm:pr-24'>
            <img loading="lazy" src={purpleStintLogo} className='object-fill py-5 mb-2'/>
          </Link> 
          <NavbarSwitch/>
        </li>
        <li className=' md:flex w-1/3 justify-around items-center hidden pl-10'> 
          <Link to="/job-board" className='paragraph-three primary-purple'>Job board</Link>
          <Link to="/blogs" className='paragraph-three'>Blogs</Link>
          <Link to="/about-us" className='paragraph-three'>About</Link>
        </li>
        <li className='md:block hidden ml-auto h-auto w-auto py-1.5 px-3 md:mr-10 rounded-full '>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLScEg-PTGW04bk_ctv62nwj0t2gj_ZBI7BVQj82L4lQtb5AI2g/viewform" className='py-3 px-5 rounded-full bg-white paragraph-three text-black font-extrabold'>
            Join our community
          </a>
        </li>
      </ul>
      <div className='md:hidden mr-2 sm:mr-5'><button onClick={toggleHamburger}>{openMenu? <RxHamburgerMenu />: <RxCross2 />}</button></div>
    </nav>
    <div className='bg-secondary-blue relative z-50'>
    {openMenu?(
      null
      ):(
        <div className='h-screen w-screen bg-secondary-blue sticky z-50 fixed-position-on-mobile'>
        <ul className='flex flex-col bg-secondary-blue pt-5 gap-y-5'>
          <li><Link onClick={toggleHamburger} to="/job-board" className='paragraph-three text-paragraph-three-mobile'>Job board</Link></li>
          <li ><Link onClick={toggleHamburger} to="/blogs" className='paragraph-three text-paragraph-three-mobile'>Blogs</Link></li>
          <li ><Link onClick={toggleHamburger} to="/about-us" className='paragraph-three text-paragraph-three-mobile'>About</Link></li>
          <li className=''>
            <a onClick={toggleHamburger} href="https://docs.google.com/forms/d/e/1FAIpQLScEg-PTGW04bk_ctv62nwj0t2gj_ZBI7BVQj82L4lQtb5AI2g/viewform" className='paragraph-three font-extrabold text-paragraph-three-mobile paragraph-three'>
              Join our community
            </a>
          </li>
        </ul>
      </div>
      )}
    </div>
  </>
  )
  
}
export default StudentNavbar;