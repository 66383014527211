import { useContext } from 'react';
import { Context } from "../App"
import { useNavigate } from 'react-router-dom';

function useView(){
    const [studentView, setStudentView] = useContext(Context)
    const navigate = useNavigate();
    function changeView(){
        return new Promise(resolve => {
            localStorage.setItem("studentView", !studentView);
            setStudentView(view => !view);
            navigate('/');
            window.scrollTo(0, 0);
            resolve(); // Resolve the promise after the view change actions are complete
          });
    }

    return {studentView, changeView}
}

export default useView;