import React from 'react';

const ToggleButton = ({ accordionOpen }) => (
  <div className="ml-4">
    <svg
      className="fill-black"
      width="12"
      height="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0"
        y="5"
        width="12"
        height="2"
        rx="1"
        className={`transform origin-center transition duration-200 ease-out ${
          accordionOpen ? '' : ''
        }`}
      />
      <rect
        x="5"
        y="0"
        width="2"
        height="12"
        rx="1"
        className={`transform origin-center transition duration-200 ease-out ${
          accordionOpen ? 'rotate-90' : 'rotate-0'
        }`}
      />
    </svg>
  </div>
);

export default ToggleButton;
