const Landing = (jobBoardScroll, communityScroll) => {

    return (
        <div class = "">
            <div class = "relative grid grid-cols-12 xl:px-14 md:gap-x-5 xl:gap-x-20 gap-y-0 pt-10 sm:pt-16 sm:pb-48 md:pt-8 md:pb-16 lg:pt-20 lg:pb-24 xl:pt-10 xl:pb-20">
                <div class = "col-span-12 md:col-span-6">
                    <p class = "text-main-heading-mobile md:text-main-heading main-heading text-primary-purple text-left">
                        The community<br />and job platform<br />for international<br />students
                    </p>
                    <p class = "md:block hidden text-paragraph-two-mobile md:text-paragraph-two paragraph-two text-primary-purple text-left pt-5">
                        Discover job opportunities tailored for international students and join a vibrant community of peers, all in one place with Stint.
                    </p>
                </div>
                <div class = "col-span-12 md:col-span-6 h-full">
                    <div class = "grid grid-cols-2 place-items-center h-full gap-x-5 md:gap-x-10">
                        <div>
                            <a href = "/job-board" class = "relative">
                                <img loading="lazy" src={require('./../../assets/Images/jobSearch.png')} class = "transition ease-in-out hover:scale-105 drop-shadow-xl rounded-3xl w-full"/>
                            </a>
                        </div>
                        <div>
                            <a href = "https://docs.google.com/forms/d/e/1FAIpQLScEg-PTGW04bk_ctv62nwj0t2gj_ZBI7BVQj82L4lQtb5AI2g/viewform" class = "relative">
                                <img loading="lazy" src={require('./../../assets/Images/joinCommunity.png')} class = "transition ease-in-out hover:scale-105 drop-shadow-xl rounded-3xl w-full"/>
                            </a>
                        </div>
                    </div>
                    <p class = "md:hidden text-paragraph-two-mobile md:text-paragraph-two paragraph-two text-primary-purple text-left">
                        Discover job opportunities tailored for international students and join a vibrant community of peers, all in one place with Stint.
                    </p>
                </div>
                <img loading="lazy" class = "h-24 sm:h-32 md:h-52 absolute translate-y-1/2 left-3/4 -translate-x-1/2 bottom-0 z-30" alt = "Icon Error" src={require('./../../assets/Images/Stint_Assets_Icons-07.png')}/>
            </div>
        </div>    
    );
  };

export default Landing;