const Value = (icon, title, text) => {
    return (
        <div class = "relative h-full bg-white rounded-2xl drop-shadow-xl">
            <p class = "px-5 paragraph-one-bold text-paragraph-one-mobile md:text-paragraph-one pt-6 text-primary-purple">
                {title}
            </p>
            <p class = "px-5 caption pt-6 text-paragraph-one-mobile md:text-caption text-primary-purple">
                {text}
            </p>
            <div class = "absolute bottom-0 left-0 w-full flex justify-start items-start pt-6">
                <img src={icon} class = "h-24 md:h-40 rounded-2xl"/>
            </div>
            <div class = "bg-whtie pt-6 h-24 md:h-40 rounded-2xl">
            </div>
        </div>
    );
  };

export default Value;