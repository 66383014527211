import Title from "../../components/Blogs/title";
import NathanAuthor from "../../assets/Images/NathanAuthor.jpg"

const Hire = () => {
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/insights" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/Hire.png')} class = "object-contain w-full h-full"/>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Hiring", 
                        "5 Reasons Why Hiring International Students Can Give Your Business a Competitive Edge", 
                        "Has your company ever considered hiring international students? If not, there are numerous reasons why you should. In this blog, we’ll explore 5 key benefits, from their multilingual skills to cultural fluency and diverse perspectives.",
                        "Nathan Lee", 
                        NathanAuthor, 
                        "20th July 2024")}
                </div>
                <div class = "pt-10 col-span-12 md:hidden w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/Hire.png')} class = "object-contain w-full h-full"/>
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-6 grid">
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Australia is a top destination for international students with world-class universities and a vibrant multicultural environment. In 2024, over 780,000 international students are enrolled in Australian universities, up 16% from pre-COVID levels (Department of Education, 2024). These students represent a diverse, multilingual talent pool for Australian employers that still remains relatively untapped.
                    </p>
                    <p class = "paragraph-two pt-5 text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Here are 5 reasons why hiring international students can give your business a competitive edge:
                    </p>
                </div>
                <div>
                    <p class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        1. Multilingual communication 
                    </p>
                    <p class = "paragraph-two pt-3  text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Many international students are bilingual or multilingual, which can be a significant asset for communicating with customers from culturally diverse backgrounds. Their language skills can enhance multilingual communication with customers across channels like sales, marketing, and events, improving customer engagement and satisfaction, and driving growth. Some of the most common languages spoken by international students include Mandarin, Hindi, Vietnamese, Indonesian and Malay. 
                    </p>
                </div>
                <div>
                    <p class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    2. Knowledge of international markets 

                    </p>
                    <p class = "paragraph-two pt-3  text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    International students have a deep understanding of their home country's market. If your company is planning to expand internationally or already operates in that country, international students can seamlessly work with employees in overseas offices. They can also provide unique insights into overseas markets and may still have valuable connections in their home country. 
                    </p>
                </div>
                <div>
                    <p class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    3. Cultural fluency
                    </p>
                    <p class = "paragraph-two pt-3  text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    Having grown up in another country, international students will have a deeper understanding of various cultures. As a result, they can effectively understand customers from diverse backgrounds and provide culturally sensitive support - contributing to improved customer satisfaction and loyalty. 
                    </p>
                </div>
                <div>
                    <p class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    4. Strong soft skills
                    </p>
                    <p class = "paragraph-two pt-3  text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    International students make considerable sacrifices when they move to a new country for university, leaving behind their family and friends. This experience helps them to develop traits that are highly valuable in the workplace, such as a strong work ethic and adaptability, resilience, hard work, loyalty and an eagerness to work.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-one-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    5. Diverse perspectives
                    </p>
                    <p class = "paragraph-two pt-3  text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                    International students come from various cultural backgrounds, offering fresh perspectives and approaches. This diversity of thought can lead to new ideas, strategies, and solutions. A diverse workplace can also improve employee satisfaction and retention, contributing to long-term business success.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-one-bold text-paragraph-two-mobile pt-6 md:pt-10 md:text-paragraph-two w-5/6">
                    Looking to hire international students from Australia’s top universities?
                    </p>
                    <p class = "paragraph-two pt-3  text-paragraph-two-mobile md:text-paragraph-two w-5/6 pb-5 md:pb-0">
                    List your jobs on Stint and connect with over 15k+ diverse, multilingual candidates from 52+ countries <a class = "text-blue-500 hover:text-blue-800 underline" href = "https://airtable.com/appbzFREDaka6w9wq/shrqPnKyf7baIvCMs">here</a>.
                    </p>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default Hire;