import Title from "../../components/Blogs/title";
import Ashley from "../../assets/Images/Ashley.jpg"
import { useEffect } from "react";
const Cindy = () => {
    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/blogs" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/Cindy.png')} class = "object-contain w-full h-full"/>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Community", 
                        "From Corporate to Startup: Navigating Career Change as an International Student in Australia", 
                        "Changing careers can be a daunting task for anyone. The risk of losing job security alongside the change in regular income are enough to deter many people from choosing to pursue a line of work that interests them more. ",
                        "Ashley Yeow", 
                        Ashley, 
                        "23rd August 2024")}
                </div>
                <div class = "pt-10 col-span-12 md:hidden w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/Cindy.png')} class = "object-contain w-full h-full"/>
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Changing careers can be a daunting task for anyone. The risk of losing job security alongside the change in regular income are enough to deter many people from choosing to pursue a line of work that interests them more. 
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        However, international student Cindy Moon decided to take the leap of faith when she quit her corporate job at Pfizer to chase her passion for fast growing and heart-driven startups.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        “When I first learned about consulting, it intrigued me the most. I got to experience different industries, but I was always the most interested in healthcare,” she explains. “So that’s when I got my very first job in Sydney, in Pfizer as a business analyst.”
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        While working for a big brand name in the healthcare industry, she initially found the work interesting because she felt like she was following societal rules. There was always the pressure to step into a corporate job and to progress up the corporate ladder. But she soon realised that she started to question her main purpose in life, unsure if working in such a commercial setting was a good fit for her. 
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        “Especially in an Asian background, there are a lot of norms where people are expected to go into a corporation. So there was a lot of pressure that I was feeling from this, yet I wasn’t sure if this was right or wrong,” Cindy says. “Then after a year, I realised I wanted to be more creative rather than being too focused on analytical and logical thinking. . My personality wasn’t the right fit for that very systematic and pragmatic corporate culture.”
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        But her time in Pfizer also taught her a lot of things. It reinforced her love for working meaningful businesses. 
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        “I decided to quit Pfizer. But I wanted to still be in the healthcare industry, but this time in the mental health space,” Cindy continues. “My main focus was to look for a mental health company, and so I found one where the founder had asked me to join them to do content creation, and to lead all their social media marketing.”
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        The leap from a corporate job at Pfizer to a mental health start-up was one that Cindy had never considered before, but she knew it was something she needed to do in order to be more involved firsthand in purpose-driven businesses. Then, managing social media for a startup opened up more doors for her to start freelancing full-time in marketing as a sole trader for meaningful, value-led businesses such as youth mental health organizations and sustainable, ethical e-commerce businesses.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Furthermore, Cindy wanted to champion the mental health of international students. Many university students in their penultimate to ultimate year find themselves in the precarious position of having to job hunt whilst maintaining their university career, alongside of having the burden of wanting to pay back their parents - this combination putting an inordinate amount of pressure on international students.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        “I completely understand that there is so much pressure for international students,” Cindy sympathises, especially since she used to be in the same situation. “There’s the pressure of having to pay your family back since they put so much financial investment into you, and if you can’t get a full-time job where the pay is enough… yeah, it’s tough.”
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Despite this, Cindy maintains that perseverance and grit are the two main skills that international students always find themselves honing, both consciously and subconsciously.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        “As long as you have the willingness and the resilience, things will work out,” she continues. “Try everything that comes your way, and try everything that comes into your mind. It will make such a huge difference. Once you start trying out things, that’s when you can start to figure out what you like and don’t like. And that’s when your career really starts to take off.”
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        The mindset that international students have when they come to Australia to find a better future is one that is extremely attractive to hiring managers. The proactiveness and ability to bounce back are aptitudes that companies always look for, but it is also important to realise that these traits are not without sacrificing time, effort, and sometimes mental health.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        And yet, Cindy maintains her positive attitude and go-getter mindset because, in the end, find a job you enjoy doing and you never work a day in your life, right? This saying was never truer when Cindy left her job at Pfizer to do social media management for a mental health startup. 
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        She concludes her interview with a happy message.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        “If you have the opportunity to do something that you are interested in, just go for it.”
                    </p>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default Cindy;