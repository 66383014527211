const TickComp = (text) => {
    return (
        <div class = "grid grid-cols-12 gap-x-1 place-items-center">
            <div>
                <img loading="lazy" src={require('./../../assets/Images/blueTick.png')} class = "w-full"/>
            </div>
            <div class = "col-span-11 w-full paragraph-two text-paragraph-two-mobile md:text-paragraph-two text-left"> 
                {text}
            </div>
        </div>
    );
  };

export default TickComp;