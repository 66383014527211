import { useEffect } from "react";
import { useNavigate  } from "react-router-dom";
import useView from '../hooks/viewHooks';

const Copmanies = () => {
  let {studentView, changeView} = useView();
  let navigate = useNavigate();

    // Go to home
    useEffect(() => {
      redirect()
  }, []);

  async function redirect(){
    if (studentView) {
      await changeView(); // Wait for the view change to complete
  }
    navigate('/')
  }

    return (
    <div></div>

    );
  };

export default Copmanies;