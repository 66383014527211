const StatComp = (img, text1, text2) => {
    return (
        <div class = "bg-white drop-shadow-lg rounded-xl grid grid-cols-12 place-items-center px-3 gap-x-4 py-5">
            <div class = "col-span-2">
                <img loading="lazy" src={img} class = "w-full md:px-1"/>
            </div>
            <div class = "col-span-10 text-left leading-tight text-left w-full"> 
                <p class = "section-heading text-section-heading-mobile md:text-section-heading">
                    {text1}
                </p>
                <p class = "paragraph-one text-paragraph-one-mobile md:text-paragraph-one">
                    {text2}
                </p>
            </div>
            
        </div>
    );
  };

export default StatComp;