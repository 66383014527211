import Title from "../../components/Blogs/title";
import BruceYovelaAuthor from "../../assets/Images/BruceYovelaAuthor.JPG"
import { useEffect } from "react";
const BruceYovela = () => {
    useEffect(() =>{
        window.scrollTo(0, 0 )
    })
    return (
    <div>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
        </style>      
        <div class = "w-5/6 relative py-5 md:py-10 md:mb-5 md:mx-8 px-4">
            <a href = "/blogs" class = "absolute left-2 w-fit">
                <svg fill="#000000" class = "bg-black w-4 md:w-8 fill-white rounded-full" version="1.1" id="Capa_1"viewBox="0 0 219.151 219.151">
                    <g>
                        <path d="M94.861,156.507c2.929,2.928,7.678,2.927,10.606,0c2.93-2.93,2.93-7.678-0.001-10.608l-28.82-28.819l83.457-0.008
                        c4.142-0.001,7.499-3.358,7.499-7.502c-0.001-4.142-3.358-7.498-7.5-7.498l-83.46,0.008l28.827-28.825
                        c2.929-2.929,2.929-7.679,0-10.607c-1.465-1.464-3.384-2.197-5.304-2.197c-1.919,0-3.838,0.733-5.303,2.196l-41.629,41.628
                        c-1.407,1.406-2.197,3.313-2.197,5.303c0.001,1.99,0.791,3.896,2.198,5.305L94.861,156.507z"/>
                    </g>
                </svg>
            </a>
        </div>
        <div class = "md:px-24 px-8 pb-8 md:pb-24">
            <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
                <div class = "md:block hidden col-span-6 w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/BruceYovela.JPG')} class = "object-contain w-full h-full"/>
                </div>
                <div class = "pt-5 md:pt-0 col-span-12 grid place-items-center md:col-span-6">
                    {Title("Community", 
                        "How Bruce and Yovela found friendship through Stint", 
                        "Bruce Doan was a second year finance major student at The University of Melbourne. Yovela Budiman was a first year computing and software systems major student at The University of Melbourne. They both met each other through Stint - Australia’s biggest community for job seeking international students.",
                        "Bruce Doan & Yovela Budiman", 
                        BruceYovelaAuthor, 
                        "27th February 2023")}
                </div>
                <div class = "pt-10 col-span-12 md:hidden w-full relative overflow-hidden h-full">
                    <img src={require('./../../assets/Images/BruceYovela.JPG')} class = "object-contain w-full h-full"/>
                </div>
            </div>
            <div class = "text-left pt-8 md:pt-16 gap-y-4 grid">
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Transitioning to university was a daunting process. As international students, this was amplified as we both had to adapt to a completely new environment— Australia, a country that promised us quality education but lacked in its ability to offer us jobs. When we first encountered STINT, we both initially thought that it would just be another community. However, this Slack-based group proved us wrong right from the beginning, as the ‘g-day channel’ immediately reflected the supportive and positive nature of the community.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        It was a safe space that gave us the freedom to not only confide in our shared struggle of searching and applying for jobs as international students but also to just be ourselves and make friends. That, we suppose, was how it all began. Through the simple act of reaching out to each other after reading each other’s messages, we learned that we had a lot in common: a shared love for Arctic Monkeys, corny jokes, and surviving university.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        We found it easy to talk to one another and help one another as we dove through the struggles of living abroad as well as adapting to a new country that was completely different to what we were used to in our own countries, Vietnam and Indonesia. There were also other channels in the slack group that allowed us to stay in touch despite being busy with our own responsibilities and not being able to continually contact each other on a day-to-day basis. For instance, the ‘Random’ channel, which allowed us to post memes and joke around. This also made it easier for us to reach out to other new members and the mod team, being more comfortable to ask questions about the job application and searching process.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        While it was nerve-wracking to talk about ourselves and our interests to a group full of strangers, we remember being encouraged by the active mod team, which helped boost our confidence in participating within the community and allowed us to feel comfortable introducing ourselves despite being new members. We had fun curating messages and opening up to the group as time passed by. Our experience of interacting with people on the Slack channels was very positive. We realized that once we opened to talk and interact with people in the community, others also seemed to gain more confidence and open themselves up to start new conversations, or discussing new topics; the moderators team caught wind of this. From time to time, more channels were added to encourage international students share about food that they enjoyed, small wins in life, and resources to help with job searching.
                    </p>
                </div>
                <div>
                    <p class = "paragraph-two text-paragraph-two-mobile md:text-paragraph-two w-5/6">
                        Going back to our friendship, the Stint team reached out to us and expressed their interest in hearing about our story in a written blog, which was about five months ago. While it turned out that we’re both terrible at keeping our promise of delivering this blog within a week, it remains true that the story of our friendship has grown bigger - with our journey together, memories, and hardship - and is impossible to be told in a blog. Our story is evidence of the magic that a community like Stint can create. We are both grateful to have join Stint, and very appreciative of Stint’s commitment to create and foster a community for international students in Australia to explore amazing career opportunities, and discover the treasure of friendship.
                    </p>
                </div>
            </div>
        </div>        
    </div>
     

    );
  };

export default BruceYovela;