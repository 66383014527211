import React from "react";
import Accordion from "./Accordion.jsx";
import faqStudentsData from '../../data/FaqStudents.json';
import faqEmployersData from '../../data/FaqEmployers.json';

const FAQ = ({ type }) => {
  const faqData = type === "employer" ? faqEmployersData : faqStudentsData;

  return (
    <div className="p-4 rounded-lg flex flex-col md:flex-row py-20 px-8 sm:px-14 md:px-16">
      {/* Left side: Heading */}
      <div className="w-full md:w-1/5 pr-2 md:pr-8">
        <h2 className="text-primary-purple text-section-heading-mobile md:text-section-heading section-heading md:text-left text-center py-8 md:py-4 leading-tight">
          Frequently asked questions
        </h2>
      </div>

      {/* Right side: FAQ items */}
      <div className="w-full md:w-4/5">
        {faqData.map((faq, index) => (
          <Accordion
            key={index}
            num={index}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </div>
    </div>
  );
};

export default FAQ;
