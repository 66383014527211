const TextDesc = (title, desc) => {
    // Break up descriptions
    let dotPoints = desc.split("BULLET")
    return (
        <div class = "pt-8 md:pr-16">
            <p class = "px-3 py-1 mb-5 paragraph-two-bold text-paragraph-two-mobile md:text-paragraph-two rounded-full w-fit text-left bg-secondary-blue">
                {title}
            </p>
            <ul class = "list-disc list-outside">
                {dotPoints.map((section) => 
                section[0] == " "?
                <li class = "text-left paragraph-three text-paragraph-three-mobile md:text-paragraph-three pt-3">
                    {section.substring(1)}
                </li>:
                <p class = "pre-wrap text-left paragraph-three text-paragraph-three-mobile md:text-paragraph-three pt-3">
                    {section}
                </p>
                )}
            </ul>
        </div>
    );
  };

export default TextDesc;