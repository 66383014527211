import { useState } from "react";

export const LocationsHook = () => {   
    // Define state to store search state
    const [locations, setLocations] = useState([]);

      // Create function to add
      function addLocationMobile(location){
        const index = locations.indexOf(location)
        // If it doesn't exists add it
        if (index == -1){
          setLocations(oldLocations => [...oldLocations, location])
        }
        // If it exists then remove it
        else{
          setLocations(oldLocations => oldLocations.filter(item => item != location));
        }
    }

    function deleteLocationsMobile(){
      setLocations([])
      let checkBoxes = document.getElementsByClassName("locationMobileChecks")
      for (let i = 0; i <checkBoxes.length; i++){
          let checkBox = checkBoxes[i];
          checkBox.checked = false;
      }
  }

    return {locations, setLocations, addLocationMobile, deleteLocationsMobile};
  }