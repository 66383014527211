const BlogImageCredit = ({ imagePath, credit}) =>{
    
    return (
        <div class = "grid grid-cols-12 gap-x-2 md:gap-x-10">
            <div class = "col-span-12 md:col-span-6">
                <img src={imagePath} class = "w-full"/>
            </div>
            <div class = "col-span-12 md:col-span-6 h-full flex items-end">
                <p class = "caption text-caption-mobile md:text-caption text-gray-500">
                    Credit: {credit}
                </p>
            </div>
        </div>
    )
};
export default BlogImageCredit;