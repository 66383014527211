import data from "./../../data/jobBoardFilters.json";

const FiltersMobile = (addIndustry, addJobType, addPrReq, deleteIndustries, deleteJobTypes, deletePrReqs, searchPress, filters, setPopup, addLocationMobile, deleteLocationsMobile) => {
    const industries = data["industries"];
    const jobType = data["jobTypes"];
    const prReq = data["prReqs"] 
    const locations = data["locations"] 
    
    function clear(){
        deleteIndustries();
        deleteJobTypes();
        deletePrReqs();
        deleteLocationsMobile();
    }

    function searchButtonPress(){
        searchPress(filters)
        setPopup(false)
    }
    return (
        <div class = "px-5 mt-5">
            <div class = "grid grid-cols-12 relative mb-10">
                <button onClick = {() => setPopup(false)}>
                    <img src={require('./../../assets/Images/Icons/cross.png')} class = "absolute right-0 max-w-3"/>
                </button>
            </div>
            <div>
                <p class = "text-left paragraph-one-bold text-paragraph-one-mobile mt-1">
                    LOCATION
                </p>
                <div class = "grid grid-cols-2 pt-4">
                    {locations.map((location) => 
                        <div class="flex items-center mb-4">
                            <label class="paragraph-four text-paragraph-four-mobile">
                                <input class="locationMobileChecks w-4 h-4 text-secondary-coral focus:ring-0 bg-white border-gray-300 rounded" type="checkbox" name="checkbox" onClick = {() => addLocationMobile(location)} value={location}/> 
                                <span class = "ml-2">
                                    {location}
                                </span>
                            </label>
                        </div>
                    )}
                </div>
                <p class = "text-left paragraph-one-bold text-paragraph-one-mobile mt-7">
                    INDUSTRY
                </p>
                <div class = "grid grid-cols-2 pt-4">
                    {industries.map((industry) => 
                        <div class="flex items-center mb-4">
                            <label class="paragraph-four text-paragraph-four-mobile">
                                <input class="industryChecks w-4 h-4 text-secondary-coral focus:ring-0 bg-white border-gray-300 rounded" type="checkbox" name="checkbox" onClick = {() => addIndustry(industry)} value={industry}/> 
                                <span class = "ml-2">
                                    {industry}
                                </span>
                            </label>
                        </div>
                    )}
                </div>
                <p class = "text-left paragraph-one-bold text-paragraph-one-mobile mt-7">
                    JOB TYPE
                </p>
                <div class = "grid grid-cols-2 pt-4">
                    {jobType.map((jobType) => 
                        <div class="flex items-center mb-4">
                            <label class="paragraph-four text-paragraph-four-mobile">
                                <input class="jobTypesChecks w-4 h-4 text-secondary-coral focus:ring-0 bg-white border-gray-300 rounded" type="checkbox" name="checkbox" onClick = {() => addJobType(jobType)} value={jobType}/> 
                                <span class = "ml-2">
                                    {jobType}
                                </span>
                            </label>
                        </div>
                    )}
                </div>
                {/* <p class = "text-left paragraph-one-bold text-paragraph-one-mobile mt-7">
                    PR REQUIREMENT
                </p>
                <div class = "grid grid-cols-2 pt-4 ">
                    {prReq.map((req) => 
                        <div class="flex items-center mb-4">
                            <label class="paragraph-four text-paragraph-four-mobile">
                                <input class="prReqsChecks w-4 h-4 text-secondary-coral focus:ring-0 bg-white border-gray-300 rounded" type="checkbox" name="checkbox" onClick = {() => addPrReq(req)} value={req}/> 
                                <span class = "ml-2">
                                    {req}
                                </span>
                            </label>
                        </div>
                    )}
                </div> */}
            </div>
            <div class = "w-full pt-8 pr-4">
                <button onClick = {() => searchButtonPress()} class = "px-8 py-3 button text-button-mobile rounded-3xl bg-secondary-coral text-white">
                    Search
                </button>
            </div>
            <div class = "w-full pt-5 pb-10 pr-4">
                <button onClick = {() => clear()} class = "w-full h-full">
                    <span class = "clear text-clear-mobile text-stone-400">
                        Clear Advanced Filters
                    </span>
                </button>
            </div>
        </div>
    );
  };

export default FiltersMobile;