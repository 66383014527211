import React from 'react';
import Offering from '../components/EmployerHome/Offering/Offering';
import FAQ from '../components/FAQs/FAQ';
import StudentTestimonial from '../components/Testimonial/StudentTestimonial';
import EmployerTestimonial from '../components/Testimonial/EmployerTestimonial';

const Tester = () => {
    return (
        <div>
        </div>
    );
  };

export default Tester;