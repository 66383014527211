import React, { useEffect, useRef } from 'react';

export default function PartnerImages() {
    const images = [
        require('../../assets/Images/parnterCityOfMelbourne.png'),
        require('../../assets/Images/partnerAAGE.png'),
        require('../../assets/Images/partnerAVIE.png'),
        require('../../assets/Images/partnerBird.png'),
        require('../../assets/Images/partnerCampusConsultancy.png'),
        require('../../assets/Images/partnerDtick.png'),
        require('../../assets/Images/partnerEnactus.png'),
        require('../../assets/Images/partnerKB.png'),
        require('../../assets/Images/partnerMISC.png'),
        require('../../assets/Images/partnerSequoria.png'),
        require('../../assets/Images/partnerSocial.png'),
        require('../../assets/Images/partnerStudyMelbourne.png'),
        require('../../assets/Images/partnerTheCouch.png'),
        require('../../assets/Images/partnerTrinityCollege.png'),
        require('../../assets/Images/partnerUniMelb.png'),
    ];

    const scrollContainerRef = useRef(null);
    const animationRef = useRef(null);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        let scrollAmount = 0;
        let paused = false;
        const scrollSpeed = 2; // Adjust this value to increase/decrease scrolling speed

        const scrollStep = () => {
            if (!paused) {
                scrollAmount += scrollSpeed;
                if (scrollAmount >= scrollContainer.scrollWidth / 2) {
                    scrollAmount = 0;
                }
                scrollContainer.scrollLeft = scrollAmount;
            }
            animationRef.current = requestAnimationFrame(scrollStep);
        };

        animationRef.current = requestAnimationFrame(scrollStep);

        const handleMouseEnter = () => {
            paused = true;
        };

        const handleMouseLeave = () => {
            paused = false;
        };

        scrollContainer.addEventListener('mouseenter', handleMouseEnter);
        scrollContainer.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            cancelAnimationFrame(animationRef.current);
            scrollContainer.removeEventListener('mouseenter', handleMouseEnter);
            scrollContainer.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, []);

    return (
        <div>
            <div className="grid grid-cols-12 pt-5">
                <div className="col-span-12">
                    <div className="horizontal-scroll-container" ref={scrollContainerRef}>
                        <div className="horizontal-scroll-content">
                            {images.map((src, index) => (
                                <img key={index} className="carousel-image" src={src} alt={`Carousel ${index}`} />
                            ))}
                            {images.map((src, index) => (
                                <img key={`dup-${index}`} className="carousel-image" src={src} alt={`Carousel duplicate ${index}`} aria-hidden="true" />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
